%breadcrumb {
  @include font(medium);
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--neutral700);
  text-transform: none;

  &:hover:not(.material-icons) {
    color: var(--secondary600);
    text-decoration: none;
  }

  &:active {
    color: var(--accent700);
  }

  &:focus-visible {
    outline: 0;
  }
}

.tag-ds {
  .breadcrumb-item,
  a.breadcrumb-item {
    @extend %breadcrumb;
    &_active {
      @extend %breadcrumb;
      font-weight: 500;
      color: var(--accent600);
    }
  }

  .breadcrumb-dots {
    margin: 0;
    &:hover {
      color: var(--secondary600);
    }
    &:active {
      color: var(--secondary700);
    }

    .material-icons {
      font-size: var(--fontSizeSmall);
    }
  }

  .breadcrumb-item-separator {
    content: '';
    width: var(--unit);
    height: var(--unit);
    background: var(--neutral700);
    mask-image: url('../icons/arrow_right.svg');
    margin: auto calc(2 * var(--unit));
    pointer-events: none;
    cursor: default;
  }

  .breadcrumb-container,
  &.breadcrumb-container {
    width: auto;
    display: flex;
    align-items: center;
    .dropdown {
      width: auto;
      display: inline-flex;

      .dropdown-menu {
        width: auto;
        left: 0;
        .dropdown-item {
          @extend %breadcrumb;
        }
      }
    }
  }

  .bg04,
  &.bg04 {
    .breadcrumb-item,
    a.breadcrumb-item {
      color: var(--neutral0);
      &:hover {
        color: var(--secondary200);
      }

      &:active {
        color: var(--secondary400);
      }
      &_active {
        color: var(--accent500);
      }
    }
    .breadcrumb-dots {
      color: var(--neutral0);
      &:hover {
        color: var(--secondary200);
      }
      &:active {
        color: var(--secondary400);
      }
    }
    .breadcrumb-item-separator {
      background: var(--neutral0);
    }
  }
}

.bg04 {
  .tag-ds,
  &.tag-ds {
    .breadcrumb-item,
    a.breadcrumb-item {
      color: var(--neutral0);
      &:hover {
        color: var(--secondary200);
      }

      &:active {
        color: var(--secondary400);
      }
      &_active {
        color: var(--accent500);
        &:hover {
          color: var(--secondary200);
        }
      }
    }
    .breadcrumb-dots {
      color: var(--neutral0);
      &:hover {
        color: var(--secondary200);
      }
      &:active {
        color: var(--secondary400);
      }
    }
    .breadcrumb-item-separator {
      background: var(--neutral0);
    }
  }
}
