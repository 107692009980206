/*INPUTS*/
%iconStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}

%input {
  appearance: none;
  outline: 0;
  width: 100%;
  max-width: inherit;
  background: var(--neutral0);
  border: calc(var(--unit) / 8) solid var(--primaryColor);
  border-radius: var(--unit);

  &::placeholder {
    color: var(--neutral500);
  }
  &:active,
  &:focus-visible {
    border: calc(var(--unit) / 8) solid var(--secondary400);
  }
  &:disabled {
    border: calc(var(--unit) / 8) solid var(--primary100);
    color: var(--neutral500);
    cursor: none;
    pointer-events: none;
  }
}

%input_medium {
  @include font(medium);
  padding: var(--unit) calc(2 * var(--unit)) var(--unit) calc(2 * var(--unit));
  max-height: calc(5 * var(--unit));
}
%input_medium_icon {
  @include font(medium);
  padding: var(--unit) calc(6 * var(--unit)) var(--unit) calc(2 * var(--unit));
}
%input_large {
  @include font(base);
  padding: calc(2 * var(--unit));
  max-height: calc(7 * var(--unit));
}
%input_large_icon {
  @include font(base);
  padding: calc(2 * var(--unit)) calc(6 * var(--unit)) calc(2 * var(--unit)) calc(2 * var(--unit));
}

%input_error {
  border: calc(var(--unit) / 8) solid var(--errorColor);
}
%input_disabled {
  border: calc(var(--unit) / 8) solid var(--primary100);
  color: var(--neutral500);
  cursor: none;
  pointer-events: none;
}

%icon_error {
  color: var(--errorColor);
}

.tag-ds {
  label {
    display: block;
    margin: 0;
    small,
    .required {
      @include font(small);
      color: var(--neutral500);
      margin-left: calc(var(--unit) / 2);
    }
  }

  label + input,
  label + .input,
  label + .input_large,
  label + .input-container,
  label + textarea,
  label + .textarea {
    margin-top: var(--unit);
  }

  .input-wrapper > label + .dropdown,
  &.input-wrapper > label + .dropdown {
    margin-top: var(--unit);
  }

  .input,
  &.input,
  .input_large,
  &.input_large {
    @extend %input;
  }

  .input,
  &.input {
    @extend %input_medium;
  }

  .input.large,
  &.input.large,
  .input_large,
  &.input_large {
    @extend %input_large;
  }

  .input_large_error,
  &.input_large_error {
    @extend %input;
    @extend %input_large;
  }

  input {
    &[type='text'],
    &[type='number'],
    &[type='password'],
    &[type='tel'],
    &[type='url'],
    &[type='email'],
    &[type='date'] {
      &.large,
      &.input_large,
      &.input_large_error {
        @extend %input_large;
      }
    }
  }

  .input.error,
  &.input.error,
  .input.large.error,
  &.input.large.error,
  .input_error,
  &.input_error,
  .input_error.large,
  &.input_error.large,
  .input_large_error,
  &.input_large_error {
    @extend %input_error;
  }

  input {
    &[type='text'],
    &[type='number'],
    &[type='password'],
    &[type='tel'],
    &[type='url'],
    &[type='email'],
    &[type='date'] {
      &.error,
      &.large.error,
      &.input_error,
      &.input_large_error {
        @extend %input_error;
      }
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='search'],
  input[type='url'],
  input[type='email'],
  input[type='date'] {
    @extend .input;
  }

  .input-container,
  &.input-container,
  .input-container_error,
  &.input-container_error,
  .input-container_disabled,
  &.input-container_disabled {
    position: relative;
    max-width: 100%;
  }

  .input-container.error input,
  &.input-container.error input,
  .input-container.error .input,
  &.input-container.error .input,
  .input-container.error .input_large,
  &.input-container.error .input_large,
  .input-container_error input,
  &.input-container_error input,
  .input-container_error .input,
  &.input-container_error .input,
  .input-container_error .input_large,
  &.input-container_error .input_large {
    @extend %input_error;
  }

  .input-icon-box,
  &.input-icon-box {
    font-size: calc(3 * var(--unit));
    position: absolute;
    right: calc(2 * var(--unit));
    top: 50%;
    transform: translateY(-50%);
    color: var(--neutral800);
  }

  .input-container input,
  &.input-container input,
  .input-container .input,
  &.input-container .input,
  .input-container_error input,
  &.input-container_error input,
  .input-container_error .input,
  &.input-container_error .input,
  .input-container_disabled input,
  &.input-container_disabled input,
  .input-container_disabled .input,
  &.input-container_disabled .input {
    @extend %input_medium_icon;
  }

  .input-container input.large,
  &.input-container input.large,
  .input-container .input.large,
  &.input-container .input.large,
  .input-container .input_large,
  .input-container input.input_large,
  &.input-container .input_large,
  &.input-container input.input_large,
  .input-container_error input.large,
  &.input-container_error input.large,
  .input-container_error .input.large,
  &.input-container_error .input.large,
  .input-container_error .input_large,
  .input-container_error input.input_large,
  &.input-container_error .input_large,
  &.input-container_error input.input_large,
  .input-container_disabled input.large,
  &.input-container_disabled input.large,
  .input-container_disabled .input.large,
  &.input-container_disabled .input.large,
  .input-container_disabled .input_large,
  .input-container_disabled input.input_large,
  &.input-container_disabled .input_large,
  &.input-container_disabled input.input_large {
    @extend %input_large_icon;
  }

  .input-container.disabled input,
  &.input-container.disabled input,
  .input-container.disabled .input,
  &.input-container.disabled .input,
  .input-container.disabled .input_large,
  &.input-container.disabled .input_large,
  .input-container_disabled input,
  &.input-container_disabled input,
  .input-container_disabled .input,
  &.input-container_disabled .input,
  .input-container_disabled .input_large,
  &.input-container_disabled .input_large {
    @extend %input_disabled;
  }

  .input-container_disabled .input-icon-box,
  &.input-container_disabled .input-icon-box,
  .input-container.disabled .input-icon-box,
  &.input-container.disabled .input-icon-box {
    color: var(--neutral500);
  }

  .input-wrapper,
  &.input-wrapper,
  .input-wrapper_error,
  &.input-wrapper_error,
  .input-wrapper_disabled,
  &.input-wrapper_disabled {
    position: relative;
    max-width: 100%;
  }

  .input-helper-text,
  &.input-helper-text {
    @include font(small);
    margin-top: var(--unit);
    color: var(--neutral800);
    span,
    i,
    svg {
      @extend %iconStyle;
      font-size: calc(2 * var(--unit));
      color: var(--neutral800);
      margin-right: var(--unit);
      float: left;
    }
  }

  .input-wrapper_disabled label,
  &.input-wrapper_disabled label {
    color: var(--neutral500);
  }

  .input-wrapper.disabled label,
  &.input-wrapper.disabled label {
    color: var(--neutral500);
    pointer-events: none;
  }

  .input-wrapper.disabled input,
  &.input-wrapper.disabled input,
  .input-wrapper.disabled .input,
  &.input-wrapper.disabled .input,
  .input-wrapper.disabled .input_large,
  &.input-wrapper.disabled .input_large,
  .input-wrapper_disabled input,
  &.input-wrapper_disabled input,
  .input-wrapper_disabled .input,
  &.input-wrapper_disabled .input,
  .input-wrapper_disabled .input_large,
  &.input-wrapper_disabled .input_large,
  .input-wrapper.disabled .input-container input,
  &.input-wrapper.disabled .input-container input,
  .input-wrapper.disabled .input-container .input,
  &.input-wrapper.disabled .input-container .input,
  .input-wrapper.disabled .input-container .input_large,
  &.input-wrapper.disabled .input-container .input_large,
  .input-wrapper_disabled .input-container input,
  &.input-wrapper_disabled .input-container input,
  .input-wrapper_disabled .input-container .input,
  &.input-wrapper_disabled .input-container .input,
  .input-wrapper_disabled .input-container .input_large,
  &.input-wrapper_disabled .input-container .input_large {
    @extend %input_disabled;
  }

  .input-wrapper.disabled .input-container .input-icon-box,
  &.input-wrapper.disabled .input-container .input-icon-box,
  .input-wrapper_disabled .input-container .input-icon-box,
  &.input-wrapper_disabled .input-container .input-icon-box {
    color: var(--neutral500);
  }

  .input-wrapper_disabled .input-helper-text,
  &.input-wrapper_disabled .input-helper-text {
    color: var(--neutral500);
  }
  .input-wrapper_disabled .input-helper-text span,
  &.input-wrapper_disabled .input-helper-text span,
  .input-wrapper_disabled .input-helper-text i,
  &.input-wrapper_disabled .input-helper-text i,
  .input-wrapper_disabled .input-helper-text svg,
  &.input-wrapper_disabled .input-helper-text svg {
    color: var(--neutral500);
  }
  .input-wrapper.disabled .input-helper-text,
  &.input-wrapper.disabled .input-helper-text {
    color: var(--neutral500);
  }
  .input-wrapper.disabled .input-helper-text span,
  &.input-wrapper.disabled .input-helper-text span,
  .input-wrapper.disabled .input-helper-text i,
  &.input-wrapper.disabled .input-helper-text i,
  .input-wrapper.disabled .input-helper-text svg,
  &.input-wrapper.disabled .input-helper-text svg {
    color: var(--neutral500);
  }

  .input-wrapper.error input,
  &.input-wrapper.error input,
  .input-wrapper.error .input,
  &.input-wrapper.error .input,
  .input-wrapper.error .input_large,
  &.input-wrapper.error .input_large,
  .input-wrapper_error input,
  &.input-wrapper_error input,
  .input-wrapper_error .input,
  &.input-wrapper_error .input,
  .input-wrapper_error .input_large,
  &.input-wrapper_error .input_large {
    @extend %input_error;
  }
  .input-wrapper.error .input-helper-text span,
  &.input-wrapper.error .input-helper-text span,
  .input-wrapper.error .input-helper-text i,
  &.input-wrapper.error .input-helper-text i,
  .input-wrapper.error .input-helper-text svg,
  &.input-wrapper.error .input-helper-text svg {
    @extend %icon_error;
  }
  .input-wrapper_error .input-helper-text span,
  &.input-wrapper_error .input-helper-text span,
  .input-wrapper.error .input-helper-text i,
  &.input-wrapper.error .input-helper-text i,
  .input-wrapper.error .input-helper-text svg,
  &.input-wrapper.error .input-helper-text svg {
    @extend %icon_error;
  }

  /* TEXTAREA */

  .textarea,
  &.textarea {
    @extend %input;
    padding: calc(2 * var(--unit));
    font-size: var(--fontSizeBase);
    min-height: 260px;
    resize: none;
  }
  .textarea::placeholder {
    @include font(base);
  }

  .input-wrapper .textarea-count,
  &.input-wrapper .textarea-count,
  .input-wrapper_error .textarea-count,
  &.input-wrapper_error .textarea-count,
  .input-wrapper_disabled .textarea-count,
  &.input-wrapper_disabled .textarea-count {
    margin-top: var(--unit);
    display: flex;
    justify-content: flex-end;
    @include font(small);
  }

  .input-wrapper .textarea-count,
  &.input-wrapper .textarea-count,
  .input-wrapper_error .textarea-count,
  &.input-wrapper_error .textarea-count {
    color: var(--neutral800);
  }

  .input-wrapper_disabled .textarea-count,
  &.input-wrapper_disabled .textarea-count {
    color: var(--neutral500);
  }

  textarea {
    @extend .textarea;
  }

  .input-wrapper.error textarea,
  &.input-wrapper.error textarea,
  .input-wrapper_error textarea,
  &.input-wrapper_error textarea {
    @extend %input_error;
  }

  /* CHECKBOX */

  .checkbox,
  &.checkbox {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: calc(2 * var(--unit));
    height: calc(2 * var(--unit));
    border: calc(var(--unit) / 8) solid var(--neutral800);
    border-radius: calc(var(--unit) / 2);
    margin: calc(var(--unit) / 2) var(--unit) auto 0;
    background: var(--neutral0);
    outline: 0;
  }

  .radio,
  &.radio {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: calc(2 * var(--unit));
    height: calc(2 * var(--unit));
    border: calc(var(--unit) / 8) solid var(--neutral800);
    border-radius: calc(var(--unit));
    margin: calc(var(--unit) / 2) var(--unit) auto 0;
    background: var(--neutral0);
    outline: 0;
  }

  %iconCheckboxStyle {
    position: absolute;
    width: var(--unit);
    height: var(--unit);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background-color: var(--neutral0);
  }

  .checkbox:checked,
  &.checkbox:checked,
  .radio:checked,
  &.radio:checked {
    background: var(--secondary400);
    border: calc(var(--unit) / 8) solid var(--secondary400);
    padding: calc(var(--unit) / 2);
    outline: 0;
  }

  .checkbox:checked::after,
  &.checkbox:checked::after {
    @extend %iconCheckboxStyle;
    mask-image: url('../icons/check.svg');
  }

  .checkbox.intermediate:checked::after,
  &.checkbox.intermediate:checked::after {
    @extend %iconCheckboxStyle;
    mask-image: url('../icons/intermediate.svg');
  }

  .radio:checked::after,
  &.radio:checked::after {
    @extend %iconCheckboxStyle;
    border-radius: 50%;
  }

  .checkbox:disabled:not(.toggle-input:disabled),
  &.checkbox:disabled:not(.toggle-input:disabled),
  .radio:disabled,
  &.radio:disabled {
    border: calc(var(--unit) / 8) solid var(--primary100);
    pointer-events: none;
    color: var(--neutral500);
    cursor: none;
    pointer-events: none;
  }

  .checkbox:checked:disabled:not(.toggle-input:checked:disabled),
  &.checkbox:checked:disabled:not(.toggle-input:checked:disabled),
  .radio:checked:disabled,
  &.radio:checked:disabled {
    background: var(--primary100);
  }

  .checkbox:disabled ~ label,
  &.checkbox:disabled ~ label,
  .radio:disabled ~ label,
  &.radio:disabled ~ label {
    color: var(--neutral500);
    pointer-events: none;
  }

  .checkbox:focus-visible,
  &.checkbox:focus-visible,
  .radio:focus-visible,
  &.radio:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--secondary300);
    outline-offset: var(--outlineOffset) solid var(--secondary300);
  }

  .checkbox:hover,
  &.checkbox:hover,
  .radio:hover,
  &.radio:hover {
    border: calc(var(--unit) / 8) solid var(--secondary400);
  }

  .checkbox:active,
  &.checkbox:active,
  .radio:active,
  &.radio:active {
    background: var(--secondary100);
  }

  .checkbox.error,
  &.checkbox.error {
    border-color: var(--errorColor);
  }
  .checkbox:checked.error:not(.toggle-input:checked.error),
  &.checkbox:checked.error:not(.toggle-input:checked.error),
  .radio:checked.error,
  &.radio:checked.error {
    background: var(--errorColor);
  }

  input[type='checkbox']:not(.toggle-input) {
    @extend .checkbox;
  }
  input[type='radio'] {
    @extend .radio;
  }

  fieldset {
    border: 0;
    padding: 0;
  }

  .checkbox-container,
  &.checkbox-container,
  .checkbox-container_error,
  &.checkbox-container_error,
  .radio-container,
  &.radio-container,
  .radio-container_error,
  &.radio-container_error {
    position: relative;
    display: flex;
    margin: calc(2 * var(--unit)) 0;
  }

  .checkbox-container label,
  &.checkbox-container label,
  .radio-container label,
  &.radio-container label,
  .checkbox-container_error label,
  &.checkbox-container_error label,
  .radio-container_error label,
  &.radio-container_error label {
    cursor: pointer;
    @include font(base);
    width: calc(100% - (3 * var(--unit)));
    justify-content: flex-end;
  }

  .checkbox-container .checkbox-text,
  &.checkbox-container .checkbox-text,
  .checkbox-container_error .checkbox-text,
  &.checkbox-container_error .checkbox-text,
  .radio-container .radio-text,
  &.radio-container .radio-text,
  .radio-container_error .radio-text,
  &.radio-container_error .radio-text {
    .subtitle {
      display: block;
      margin-bottom: calc(var(--unit) / 2);
    }
    p {
      @include font(base);
      margin: 0;
    }
  }

  .checkbox-container.error,
  &.checkbox-container.error,
  .checkbox-container_error,
  &.checkbox-container_error,
  .radio-container.error,
  &.radio-container.error,
  .radio-container_error,
  &.radio-container_error {
    .checkbox {
      border-color: var(--errorColor) !important;
    }
    .checkbox:checked:not(.toggle-input:checked),
    .radio:checked {
      background: var(--errorColor) !important;
    }
  }

  .checkbox-group-container,
  &.checkbox-group-container,
  .radio-group-container,
  &.radio-group-container {
    width: 100%;
  }

  .checkbox-group-container caption,
  &.checkbox-group-container caption,
  .checkbox-group-container .caption,
  &.checkbox-group-container .caption,
  .radio-group-container caption,
  &.radio-group-container caption,
  .radio-group-container .caption,
  &.radio-group-container .caption {
    display: block;
    text-align: left;
  }

  .checkbox-group-container .checkbox-group,
  &.checkbox-group-container .checkbox-group,
  .radio-group-container .radio-group,
  &.radio-group-container .radio-group {
    &_horizontal {
      display: flex;
      .checkbox-container,
      .checkbox-container_error,
      .radio-container,
      .radio-container_error {
        margin: calc(2 * var(--unit)) calc(2 * var(--unit)) calc(2 * var(--unit)) 0;
      }
    }
    &_child {
      margin-left: calc(3 * var(--unit));
    }
    &_horizontal_child,
    &_child_horizontal {
      @extend .checkbox-group_horizontal;
      @extend .checkbox-group_child;
    }
  }

  .checkbox-group-container .error-text,
  &.checkbox-group-container .error-text,
  .radio-group-container .error-text,
  &.radio-group-container .error-text {
    margin: calc(2 * var(--unit)) 0 0 0;
    width: 100%;
    color: var(--neutral700);
    @include font(small);
    span {
      @extend %iconStyle;
      font-size: calc(2 * var(--unit));
      color: var(--errorColor);
      margin-right: var(--unit);
      float: left;
    }
  }

  /* SEARCHBOX */
  %searchbox_medium {
    @include font(base);
    padding: calc(2 * var(--unit)) calc(2 * var(--unit)) calc(2 * var(--unit)) calc(6 * var(--unit));
    height: calc(7 * var(--unit));
    max-height: inherit;
  }
  %searchbox_small {
    @include font(medium);
    padding: var(--unit) calc(2 * var(--unit)) var(--unit) calc(6 * var(--unit));
  }

  .searchbox,
  &.searchbox,
  input[type='text'].searchbox,
  input[type='search'].searchbox,
  .searchbox_small,
  &.searchbox_small,
  input[type='text'].searchbox_small,
  input[type='search'].searchbox_small {
    margin: 0;
    width: 100%;
    background: var(--neutral100);
    color: var(--neutral800);
    border: 0;
    border-radius: var(--unit);
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      position: relative;
      height: calc(3 * var(--unit));
      width: calc(3 * var(--unit));
      background-color: transparent;
      cursor: pointer;
    }

    &::placeholder {
      color: var(--neutral500);
    }
    &:focus-visible {
      outline: calc(var(--unit) / 2) solid var(--secondary300);
    }
  }

  .searchbox,
  &.searchbox,
  input[type='text'].searchbox,
  input[type='search'].searchbox {
    @extend %searchbox_medium;
  }

  .searchbox_small,
  &.searchbox_small,
  .searchbox.small,
  &.searchbox.small,
  input[type='text'].searchbox_small,
  input[type='text'].searchbox.small,
  input[type='search'].searchbox_small,
  input[type='search'].searchbox.small {
    @extend %searchbox_small;
  }

  .bg02,
  &.bg02,
  .bg03,
  &.bg03 {
    .searchbox,
    input[type='text'].searchbox,
    input[type='search'].searchbox,
    .searchbox_small,
    input[type='text'].searchbox_small,
    input[type='search'].searchbox_small {
      background: var(--neutral0);
    }
  }

  .input-container,
  &.input-container {
    .search {
      position: absolute;
      color: var(--neutral500);
      left: calc(2 * var(--unit));
      top: 50%;
      transform: translateY(-50%);
    }
    .button-secondary {
      margin: 0;
      position: absolute;
      &:has(.material-icons:not(.left, .right)) {
        width: calc(7 * var(--unit));
        height: 100%;
        padding: calc(2 * var(--unit));
      }
      background: inherit;
      border: 0;
      top: 0;
      right: 0;
      &:hover {
        background: inherit;
        border: 0 !important;
      }
      &:focus-visible {
        border: 0;
      }
    }
    &:hover .search,
    &:active .search {
      color: var(--neutral800);
    }
  }

  /*SELECT*/
  %select_medium {
    @include font(medium);
    padding: var(--unit) calc(6 * var(--unit)) var(--unit) calc(2 * var(--unit));
  }
  %select_large {
    @include font(base);
    padding: calc(2 * var(--unit)) calc(6 * var(--unit)) calc(2 * var(--unit)) calc(2 * var(--unit));
  }

  .select,
  &.select,
  .select_large,
  &.select_large {
    @extend %input;
    appearance: auto;
  }
  .select-multiple,
  &.select-multiple {
    @extend .textarea;
    overflow-y: auto;
  }

  .select,
  &.select {
    @extend %select_medium;
  }

  .select.large,
  &.select.large,
  .select_large,
  &.select_large,
  select.large,
  select.select_large {
    @extend %select_large;
  }

  .select[multiple],
  &.select[multiple],
  .select_large[multiple],
  &.select_large[multiple] {
    @extend .select-multiple;
  }

  .select_large_error,
  &.select_large_error {
    @extend .select_large;
  }

  .select.error,
  &.select.error,
  .select.large.error,
  &.select.large.error,
  .select_error,
  &.select_error,
  .select_error.large,
  &.select_error.large,
  .select_large_error,
  &.select_large_error,
  select.error,
  select.select_error,
  select.large.error,
  select.select_large_error {
    @extend %input_error;
  }

  select {
    @extend .select;
  }

  .input-wrapper .input-container select,
  &.input-wrapper .input-container select,
  .input-wrapper .input-container .select,
  &.input-wrapper .input-container .select,
  .input-wrapper .input-container .select_large,
  &.input-wrapper .input-container .select_large,
  .input-wrapper .input-container .select-multiple,
  &.input-wrapper .input-container .select-multiple,
  .input-wrapper .input-container .select_error,
  &.input-wrapper .input-container .select_error,
  .input-wrapper .input-container .select_large_error,
  &.input-wrapper .input-container .select_large_error {
    margin-top: 0;
  }

  .input-wrapper label ~ select,
  &.input-wrapper label ~ select,
  .input-wrapper label ~ .select,
  &.input-wrapper label ~ .select,
  .input-wrapper label ~ .select_large,
  &.input-wrapper label ~ .select_large {
    margin-top: var(--unit);
  }

  .input-wrapper.disabled select,
  &.input-wrapper.disabled select,
  .input-wrapper.disabled .select,
  &.input-wrapper.disabled .select,
  .input-wrapper.disabled .select_large,
  &.input-wrapper.disabled .select_large,
  .input-wrapper_disabled select,
  &.input-wrapper_disabled select,
  .input-wrapper_disabled .select,
  &.input-wrapper_disabled .select,
  .input-wrapper_disabled .select_large,
  &.input-wrapper_disabled .select_large,
  .input-wrapper.disabled .input-container select,
  &.input-wrapper.disabled .input-container select,
  .input-wrapper.disabled .input-container .select,
  &.input-wrapper.disabled .input-container .select,
  .input-wrapper.disabled .input-container .select_large,
  &.input-wrapper.disabled .input-container .select_large,
  .input-wrapper_disabled .input-container select,
  &.input-wrapper_disabled .input-container select,
  .input-wrapper_disabled .input-container .select,
  &.input-wrapper_disabled .input-container .select,
  .input-wrapper_disabled .input-container .select_large,
  &.input-wrapper_disabled .input-container .select_large {
    border: calc(var(--unit) / 8) solid var(--primary100);
    color: var(--neutral500);
    cursor: none;
    pointer-events: none;
  }

  .input-wrapper.error select,
  &.input-wrapper.error select,
  .input-wrapper.error .select,
  &.input-wrapper.error .select,
  .input-wrapper.error .select_large,
  &.input-wrapper.error .select_large,
  .input-wrapper_error select,
  &.input-wrapper_error select,
  .input-wrapper_error .select,
  &.input-wrapper_error .select,
  .input-wrapper_error .select_large,
  &.input-wrapper_error .select_large {
    @extend %input_error;
  }

  .input-wrapper,
  &.input-wrapper,
  .input-wrapper_disabled,
  &.input-wrapper_disabled,
  .input-wrapper_error,
  &.input-wrapper_error {
    .select-bottom-container {
      margin-top: var(--unit);
      display: flex;
      align-items: center;
      .input-helper-text {
        margin: auto auto auto var(--unit);
      }
    }
  }

  /* DATEPICKER */
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  .datepicker,
  &.datepicker {
    text-transform: uppercase;
    padding: var(--unit) calc(2 * var(--unit));
  }
  input[type='date']:invalid::-webkit-datetime-edit,
  input[type='datetime']:invalid::-webkit-datetime-edit,
  input[type='datetime-local']:invalid::-webkit-datetime-edit {
    color: var(--neutral500);
  }
  ::-webkit-calendar-picker-indicator {
    width: calc(3 * var(--unit));
    height: calc(3 * var(--unit));
  }
}

fieldset.tag-ds {
  border: 0;
  padding: 0;
}
