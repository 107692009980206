.tag-ds {

  .number-input, &.number-input {

    input[type=number] {
      text-align: center;
    }
    
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
      -webkit-appearance: none;
    }
    
    .input-number-container {
      position: relative;
    }
    
    label ~ .input-number-container {
      margin-top: var(--unit);
    }
    
    button.input-number-minus {
      @extend %button-icon_interactive;
      padding: 0;
      position: absolute;
      height: calc(3 * var(--unit));
      width: calc(3 * var(--unit));
      left: var(--unit);
      background-color: var(--neutral100);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 50%;
      transform: translateY(50%);
      bottom: 50%;
      font-weight: 500;

    }
    
    button.input-number-plus{
      @extend %button-icon_interactive;
      padding: 0;
      position: absolute;
      font-weight: 500;
      height: calc(3 * var(--unit));
      width: calc(3 * var(--unit));
      right: var(--unit);
      background-color: var(--neutral100);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
    }
  }
}