::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;

  &:hover {
    background-color: #ebebee;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  width: 8px;
  background-color: #adadb5;
}
