.tag-ds {
  .tooltip,
  &.tooltip {
    @include font(small);
    display: inline-flex;
    align-items: center;
    min-height: calc(4 * var(--unit));
    background-color: var(--neutral800);
    border-radius: calc(0.75 * var(--unit));
    color: var(--neutral0);
    padding: var(--unit) calc(2 * var(--unit));
    position: absolute;
    opacity: 1;
  }
  .tooltip::before,
  &.tooltip::before {
    position: absolute;
    height: calc(var(--unit) - 1px);
    width: calc(var(--unit) - 1px);
    background-color: var(--neutral800);
    content: '';
  }
  .tooltip.bottom::before,
  &.tooltip.bottom::before {
    border-radius: 2px 0 0 0;
    border-bottom-color: var(--neutral800);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .tooltip.top::before,
  &.tooltip.top::before {
    border-radius: 0 0 2px 0;
    border-top-color: var(--neutral800);
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .tooltip.left::before,
  &.tooltip.left::before {
    border-radius: 0 2px 0 0;
    border-left-color: var(--neutral800);
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .tooltip.right::before,
  &.tooltip.right::before {
    border-radius: 0 0 0 2px;
    border-right-color: var(--neutral800);
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .bg04,
  &.bg04,
  .bgAccent,
  &.bgAccent,
  .bgGradient,
  &.bgGradient {
    .tooltip {
      background-color: var(--neutral0);
      color: var(--neutral800);
    }
    .tooltip.top::before {
      border-top-color: var(--neutral0);
    }
    .tooltip.right::before {
      border-right-color: var(--neutral0);
    }
    .tooltip.bottom::before {
      border-bottom-color: var(--neutral0);
    }
    .tooltip.left::before {
      border-left-color: var(--neutral0);
    }
  }
}
