/* TOGGLE */
.tag-ds {
  .toggle,
  &.toggle {
    position: relative;
    cursor: default;
  }
  .toggle-label {
    @include font(medium);
    color: var(--neutral800);
    margin: 0 0 calc(2 * var(--unit)) 0;
  }

  %toggle-pill {
    position: relative;
    cursor: pointer;
    border: calc(var(--unit) / 8) solid var(--primaryColor);
    background: var(--neutral0);
    transition: background-color 0.2s;
    &:hover {
      border: calc(var(--unit) / 8) solid var(--secondary400);
    }
  }

  %toggle-medium {
    width: calc(6 * var(--unit));
    height: calc(3 * var(--unit));
    border-radius: calc(1.5 * var(--unit));
  }
  %toggle-small {
    width: calc(4 * var(--unit));
    height: calc(2 * var(--unit));
    border-radius: calc(1.5 * var(--unit));
  }

  .toggle-container {
    display: flex;
    position: relative;
  }

  .toggle-pill,
  .toggle-pill_checked,
  .toggle-pill_disabled,
  .toggle-pill_checked_disabled,
  .toggle-pill_small,
  .toggle-pill_small_checked,
  .toggle-pill_small_disabled,
  .toggle-pill_small_checked_disabled {
    @extend %toggle-pill;
  }

  .toggle-pill,
  .toggle-pill_checked,
  .toggle-pill_disabled,
  .toggle-pill_checked_disabled {
    @extend %toggle-medium;
  }
  .toggle-pill_small,
  .toggle-pill_small_checked,
  .toggle-pill_small_disabled,
  .toggle-pill_small_checked_disabled {
    @extend %toggle-small;
  }

  %toggle-pill_checked {
    background: var(--secondaryColor);
    border: calc(var(--unit) / 8) solid var(--secondaryColor);
  }
  .toggle-pill_checked {
    @extend %toggle-pill_checked;
  }
  .toggle-pill_small_checked {
    @extend %toggle-pill_checked;
  }

  %toggle-pill_disabled {
    border: calc(var(--unit) / 8) solid var(--primary100);
    pointer-events: none;
    .toggle-handle {
      background: var(--primary100);
    }
  }

  .toggle-pill_disabled,
  .toggle.disabled .toggle-pill {
    @extend %toggle-pill_disabled;
  }
  .toggle-pill_small_disabled,
  .toggle.disabled .toggle-pill_small {
    @extend %toggle-pill_disabled;
  }

  %toggle-pill_checked_disabled {
    background: var(--primary100);
    border: calc(var(--unit) / 8) solid var(--primary100);
    pointer-events: none;
    .toggle-handle,
    .toggle-handle_small {
      background: var(--neutral0);
    }
  }

  .toggle-pill_checked_disabled {
    @extend %toggle-pill;
  }
  .toggle-pill_checked_disabled,
  .toggle.disabled .toggle-pill_checked {
    @extend %toggle-medium;
    @extend %toggle-pill_checked_disabled;
  }
  .toggle-pill_small_checked_disabled {
    @extend %toggle-pill;
  }
  .toggle-pill_small_checked_disabled,
  .toggle.disabled .toggle-pill_small_checked {
    @extend %toggle-small;
    @extend %toggle-pill_checked_disabled;
  }

  %toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    margin: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    appearance: none;

    &:focus-visible {
      opacity: 1;
      appearance: none;
      outline: calc(var(--unit) / 2) solid var(--secondary300);
      margin: -1px;
    }
    &:disabled,
    &:read-only {
      appearance: none;
    }
  }

  .toggle-input {
    @extend %toggle;
  }
  .toggle-pill > .toggle-input,
  .toggle-pill_disabled > .toggle-input,
  .toggle-pill_checked > .toggle-input {
    @extend %toggle-medium;
  }
  .toggle-pill_small > .toggle-input,
  .toggle-pill_small_disabled > .toggle-input,
  .toggle-pill_small_checked > .toggle-input {
    @extend %toggle-small;
  }

  %toggle-handle {
    position: absolute;
    cursor: pointer;
    transition: 0.2s;
    background: var(--primaryColor);
    z-index: 1;
  }

  %toggle-handle-medium {
    top: calc(3 * var(--unit) / 8);
    left: calc(3 * var(--unit) / 8);
    width: calc(2 * var(--unit));
    height: calc(2 * var(--unit));
    border-radius: calc(2 * var(--unit));
  }

  %toggle-handle-small {
    top: calc(2.5 * var(--unit) / 16);
    left: calc(1.5 * var(--unit) / 8);
    width: calc(1.5 * var(--unit));
    height: calc(1.5 * var(--unit));
    border-radius: calc(1.5 * var(--unit));
  }
  .toggle-handle {
    @extend %toggle-handle;
  }
  .toggle-pill > .toggle-handle,
  .toggle-pill_checked > .toggle-handle,
  .toggle-pill_disabled > .toggle-handle,
  .toggle-pill_checked_disabled > .toggle-handle {
    @extend %toggle-handle-medium;
  }
  .toggle-pill_checked > .toggle-handle {
    left: calc(100% - (3 * var(--unit) / 8));
    transform: translateX(-100%);
    background: var(--neutral0);
  }
  .toggle-pill_disabled > .toggle-handle,
  .toggle.disabled .toggle-pill > .toggle-handle {
    left: calc(3 * var(--unit) / 8);
    background: var(--primary100);
  }
  .toggle-pill_checked_disabled > .toggle-handle,
  .toggle.disabled .toggle-pill_checked > .toggle-handle {
    left: calc(100% - (3 * var(--unit) / 8));
    transform: translateX(-100%);
    background: var(--neutral0);
  }

  .toggle-pill_small > .toggle-handle,
  .toggle-pill_small_checked > .toggle-handle,
  .toggle-pill_small_disabled > .toggle-handle,
  .toggle-pill_small_checked_disabled > .toggle-handle {
    @extend %toggle-handle-small;
  }

  .toggle-pill_small_checked > .toggle-handle {
    left: calc(100% - (1.5 * var(--unit) / 8));
    transform: translateX(-100%);
    background: var(--neutral0);
  }
  .toggle-pill_small_disabled > .toggle-handle,
  .toggle.disabled .toggle-pill_small > .toggle-handle {
    left: calc(1.5 * var(--unit) / 8);
    background: var(--primary100);
  }
  .toggle-pill_small_checked_disabled > .toggle-handle {
    left: calc(100% - (1.5 * var(--unit) / 8));
    transform: translateX(-100%);
    background: var(--neutral0);
  }

  %toggle-text-helper {
    margin-left: var(--unit);
    color: var(--neutral700);
  }
  .toggle-text-helper {
    @include font(medium);
    @extend %toggle-text-helper;
  }

  .toggle-text-helper_small {
    @include font(small);
    @extend %toggle-text-helper;
  }
}
