.tag-ds {
  .rating,
  &.rating {
    display: flex;
    gap: var(--unit);
    position: relative;
    width: fit-content;

    &:has(.rating-label) {
      margin-bottom: calc(3 * var(--unit));
    }

    button.rating-button {
      width: calc(5 * var(--unit));
      height: calc(5 * var(--unit));
      color: var(--neutral800);
      background-color: var(--neutral0);
      padding: 0;
      min-width: unset;
      border: calc(var(--unit) / 8) solid var(--secondary400);
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: var(--rating-button-opacity);
        background-color: var(--secondary100);
        border-radius: var(--unit);
      }

      &:focus {
        border-width: calc(var(--unit) / 1.6);
      }
    }

    &-label {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: calc(-3 * var(--unit));
      width: 100%;
    }

    @media (max-width: $mobile) {
      gap: 0;
      button.rating-button {
        width: calc(4 * var(--unit));
        border-radius: 0;
        &:focus {
          border-width: none;
          outline: calc(var(--unit) / (8 / 3)) solid var(--secondary400);
          border-width: calc(var(--unit) / 8);
          border-radius: calc(var(--unit) / 2);
          z-index: 2;
        }
        &::before {
          border-radius: 0;
        }
        &:first-child,
        &:first-child::before {
          border-top-left-radius: calc(var(--unit) / 2);
          border-bottom-left-radius: calc(var(--unit) / 2);
        }
        &:last-of-type,
        &:last-of-type::before {
          border-top-right-radius: calc(var(--unit) / 2);
          border-bottom-right-radius: calc(var(--unit) / 2);
        }
      }
    }
  }
}
