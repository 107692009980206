/* Vars */
%avatar_disabled {
  opacity: 40%;
  pointer-events: none;
}

%avatar-base {
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral200);
}

%avatar-button-base {
  min-width: auto;
  height: auto;
  background-color: inherit;
  border: 0;
  border-radius: 0;
  padding: 0;

  &:hover {
    .avatar-content {
      outline: calc(var(--unit) / 8) solid var(--secondary400);
    }
  }

  &:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--secondary300);
  }

  &:disabled {
    @extend %avatar_disabled;
  }

  .avatar-content {
    @extend %avatar-base;
  }
}

%avatar_big {
  @extend %avatar-base;
  width: calc(8 * var(--unit));
  height: calc(8 * var(--unit));
  img {
    width: calc(8 * var(--unit));
    height: calc(8 * var(--unit));
  }
}

%avatar {
  @extend %avatar-base;
  width: calc(7 * var(--unit));
  height: calc(7 * var(--unit));
  img {
    width: calc(7 * var(--unit));
    height: calc(7 * var(--unit));
  }
}

%avatar_small {
  @extend %avatar-base;
  width: calc(6 * var(--unit));
  height: calc(6 * var(--unit));
  img {
    width: calc(6 * var(--unit));
    height: calc(6 * var(--unit));
  }
}

%avatar_xsmall {
  @extend %avatar-base;
  width: calc(5 * var(--unit));
  height: calc(5 * var(--unit));
  img {
    width: calc(5 * var(--unit));
    height: calc(5 * var(--unit));
  }
}

.tag-ds {
  .avatar,
  &.avatar {
    @extend %avatar;
    &_disabled {
      @extend %avatar;
    }
    &_big {
      @extend %avatar_big;
      &_disabled {
        @extend %avatar_big;
      }
    }
    &_small {
      @extend %avatar_small;
      &_disabled {
        @extend %avatar_small;
      }
    }
    &_xsmall {
      @extend %avatar_xsmall;
      &_disabled {
        @extend %avatar_xsmall;
      }
    }
  }

  button.avatar {
    @extend %avatar-button-base;
    .avatar-content {
      @extend %avatar;
    }
    &_big,
    &_small,
    &_xsmall {
      @extend %avatar-button-base;
    }
    &_big {
      @extend %avatar_big;
    }
    &_small {
      @extend %avatar_small;
    }
    &_xsmall {
      @extend %avatar_xsmall;
    }
  }

  .avatar-title {
    @extend %small-title;
    color: var(--neutral800);
    margin-left: var(--unit);
  }

  .avatar-subtitle {
    @include font(small);
    color: var(--neutral700);
    margin-left: var(--unit);
    margin-top: calc(var(--unit) / 2);
  }

  .avatar-wrapper,
  &.avatar-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
  }

  .avatar-text-wrapper {
    span {
      display: block;
    }

    & > :not(:first-child) {
      margin-top: 4px;
    }
  }
  .avatar-wrapper_disabled,
  &.avatar-wrapper_disabled {
    @extend .avatar-wrapper;
  }
  .avatar-wrapper.disabled,
  &.avatar-wrapper.disabled,
  .avatar-wrapper_disabled,
  &.avatar-wrapper_disabled {
    .avatar {
      @extend %avatar_disabled;
      &_big,
      &_small,
      &_xsmall {
        @extend %avatar_disabled;
      }
    }
    .avatar-title,
    .avatar-subtitle {
      color: var(--neutral500);
    }
  }

  .avatar-wrapper,
  &.avatar-wrapper {
    .avatar-container {
      position: relative;
      .avatar {
        & ~ .badge,
        & ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(4.5 * var(--unit));
        }

        &_big ~ .badge,
        &_big ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(5.5 * var(--unit));
        }
        &_small ~ .badge,
        &_small ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(3.5 * var(--unit));
        }
        &_xsmall ~ .badge,
        &_xsmall ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(3.5 * var(--unit));
        }
      }
    }

    &_disabled {
      .avatar {
        & ~ .badge,
        & ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(4.5 * var(--unit));
        }

        &_big ~ .badge,
        &_big ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(5.5 * var(--unit));
        }
        &_small ~ .badge,
        &_small ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(3.5 * var(--unit));
        }
        &_xsmall ~ .badge,
        &_xsmall ~ .badge_empty {
          position: absolute;
          top: calc(-1 * var(--unit) / 2);
          left: calc(3.5 * var(--unit));
        }
      }
    }
  }
}
