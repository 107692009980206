.tag-ds {
  @media (max-width: $mobile) {
    .splash-screen,
    &.splash-screen,
    .splash-screen_accent,
    &.splash-screen_accent {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    .splash-screen_accent,
    &.splash-screen_accent {
      background: var(--accentColor);
    }

    .splash-screen-logo {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(15 * var(--unit));
      height: auto;
      aspect-ratio: 4/2;
    }

    .splash-screen-video video {
      width: 100vw;
      height: 100%;
      aspect-ratio: 9/16;
    }
  }
}
