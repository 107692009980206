%drawer {
  @extend .shadow-elevation_1x;
  background-color: var(--neutral0);
  padding: calc(4 * var(--unit));
  display: flex;
  flex-direction: column;
  width: calc(41 * var(--unit));
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.tag-ds {
  .drawer-overlay,
  &.drawer-overlay {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    &::after {
      content: '';
      background-color: var(--neutral600);
      width: 100%;
      height: 100%;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .drawer-wrapper,
  &.drawer-wrapper {
    @extend %drawer;

    .drawer-buttons-container {
      position: inherit;
      margin-bottom: calc(3 * var(--unit));
    }

    button.drawer-close-button,
    button.drawer-back-button {
      @extend %button-icon_interactive;
      &:has(.material-icons:not(.left, .right)) {
        width: calc(3 * var(--unit));
        height: calc(3 * var(--unit));
        padding: 0;
      }
    }

    button.drawer-close-button {
      margin-left: auto;
    }

    .drawer-header {
      flex: 0 1 auto;
      margin-bottom: calc(4 * var(--unit));
    }
    .drawer-body {
      flex: 1 1 auto;
    }
    .drawer-footer {
      flex: 0 1 auto;
      margin-top: auto;
    }
  }

  @media (max-width: $mobile) {
    .drawer-wrapper {
      padding: calc(3 * var(--unit));
      .drawer-header {
        margin-bottom: calc(3 * var(--unit));
      }
    }
  }
}
