.tag-ds {
  form {
    padding: calc(5 * var(--unit));
    width: 100%;

    & .head-container {
      margin-bottom: calc(4 * var(--unit));
      .head-title {
        margin-bottom: var(--unit);
      }
    }
  }

  @media (max-width: $mobile) {
    form {
      display: block;
      padding: 0;
    }
  }
}