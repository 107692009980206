%footer-item {
  @include font(medium);
  color: var(--neutral800);
  text-transform: none;
}
.tag-ds {
  .footer-container,
  &.footer-container {
    background-color: var(--neutral200);
    padding: calc(10 * var(--unit)) calc(18 * var(--unit));
    a {
      margin-top: 0;
    }
  }

  .footer-header,
  .footer-bottom {
    display: flex;
  }

  .footer-useful-links,
  .footer-policies-links {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-social-media,
  .footer-brand {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    align-items: center;
  }

  .footer-logo {
    margin-right: calc(6 * var(--unit));
    max-width: calc(10 * var(--unit));
  }

  hr {
    background-color: var(--neutral400);
    margin: calc(4 * var(--unit)) 0;
  }

  .footer-useful-links,
  .footer-social-media,
  .footer-policies-links {
    gap: calc(6 * var(--unit));
  }

  .footer-useful-links > *,
  .footer-social-media > * {
    @extend %footer-item;
    font-weight: 500;
  }

  .footer-policies-links > * {
    @extend %footer-item;
    color: var(--neutral700);
    font-weight: 400;
  }

  .footer-brand {
    @extend %footer-item;
    color: var(--neutral500);
  }

  .bg04,
  &.bg04 {
    .footer-container {
      background-color: var(--neutral800);
      .footer-useful-links > *,
      .footer-social-media > *,
      .footer-policies-links > * {
        color: var(--neutral0);
        &:hover {
          color: var(--secondary200);
        }
        &:active {
          color: var(--secondary400);
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .footer-container {
      padding: calc(4 * var(--unit)) calc(2 * var(--unit)) calc(3 * var(--unit));
      width: 100%;
      .footer-useful-links,
      .footer-policies-links {
        display: block;
        text-align: left;
        *:not(:first-child) {
          margin-top: calc(3 * var(--unit));
        }
      }

      .footer-social-media {
        justify-content: flex-start;
        margin-top: calc(6 * var(--unit));
      }

      .footer-header,
      .footer-bottom {
        display: block;
      }

      .footer-bottom-items {
        margin-top: calc(4 * var(--unit));
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
