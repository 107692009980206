.tag-ds {
  .filtered-search,
  &.filtered-search {
    .filtered-search-top {
      width: 100%;
      display: flex;
      align-items: center;
      gap: calc(2 * var(--unit));
      padding: 0 calc(4 * var(--unit));
    }

    .filtered-search-filter-container {
      width: 100%;
      margin-top: calc(4 * var(--unit));
      background-color: var(--neutral100);
      padding: calc(2 * var(--unit)) calc(10 * var(--unit));
      position: relative;

      & > .filtered-search-filter-navigation_left {
        @extend %button-icon_interactive;
        height: calc(4 * var(--unit));
        width: calc(4 * var(--unit));
        border-radius: 50%;
        background-color: var(--neutral0);
        position: absolute;
        transform: translateY(10%);
        left: calc(4 * var(--unit));
      }

      & > .filtered-search-filter-navigation_right {
        @extend %button-icon_interactive;
        position: absolute;
        right: calc(4 * var(--unit));
        transform: translateY(-110%);
        height: calc(4 * var(--unit));
        width: calc(4 * var(--unit));
        border-radius: 50%;
        background-color: var(--neutral0);
      }

      & > .filtered-searc-filter-container-inner {
        display: flex;
        gap: calc(2 * var(--unit));
        align-items: center;
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
      }
    }

    @media (max-width: $mobile) {
      .filtered-search-top {
        flex-direction: column;
        & > * {
          width: 100%;
        }
        .filtered-search-toggle {
          margin: auto;
        }
      }
      .filtered-search-filter-container {
        padding: calc(2 * var(--unit)) calc(4 * var(--unit));

        .filtered-searc-filter-container-inner {
          overflow-x: scroll;
        }
      }
    }
  }
}
