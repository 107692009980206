.tag-ds {
    .quote-wrapper, &.quote-wrapper{
        width: calc(42* var(--unit));
    }
    .quote {
        @extend .h3;
        padding-bottom: calc(3* var(--unit));
    }
    .quote-icon {
        display: block;
        color: var(--accent500);
        font-size: calc(6* var(--unit));
        padding-bottom: calc(var(--unit));
    }

    .quote-avatar {
        & .avatar-title {
            @include font(base);
            font-weight: 500;
            margin-left: 0;
        }
        & .avatar-subtitle {
            @include font(small);
            margin-left: 0;
        }

        & .avatar_small{
            margin-right: calc(var(--unit));
        }

    }
}