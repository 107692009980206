.tag-ds {
  .empty-state {
    text-align: left;
    & button {
      margin-top: calc(4 * var(--unit));
    }
    .empty-state-title {
      margin: var(--unit) 0;
    }

    a {
      margin-top: calc(3 * var(--unit));
    }
  }
}
