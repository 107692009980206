.tag-ds {
  .content-switcher,
  &.content-switcher {
    width: auto;
  }

  .content-switcher-items {
    display: inline-flex;
    max-height: calc(7 * var(--unit));
    padding: var(--unit);
    gap: var(--unit);
    border-radius: var(--unit);
    border: calc(var(--unit) / 8) solid var(--neutral200);
    margin-bottom: calc(3 * var(--unit));

    &_divider {
      @extend .content-switcher-items;
    }

    &_small,
    &_small_divider {
      @extend .content-switcher-items;
      padding: calc(var(--unit) / 2);
      max-height: calc(5 * var(--unit));
    }

    &_divider,
    &_small_divider {
      .content-switcher-item-button:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: calc(-1 * var(--unit) / 4);
          width: calc(var(--unit) / 8);
          background-color: var(--neutral200);
        }
        &.active::after {
          content: none;
        }
      }
    }

    &_divider {
      .content-switcher-item-button:not(:last-child) {
        &::after {
          height: calc(3 * var(--unit));
        }
      }
    }

    &_small_divider {
      .content-switcher-item-button:not(:last-child) {
        &::after {
          height: calc(2 * var(--unit));
        }
      }
    }
  }

  button.content-switcher-item-button {
    @include font(medium);
    font-weight: 500;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: left;
    width: calc(20 * var(--unit));
    max-height: calc(5 * var(--unit));

    border: calc(var(--unit) / 8) solid transparent;
    border-radius: var(--unit);
    padding: var(--unit);
    background: inherit;
    color: var(--neutral700);
    text-transform: none;

    &:disabled {
      color: var(--neutral500);
      pointer-events: none;
    }

    &:hover {
      border: calc(var(--unit) / 8) solid var(--secondary400);
    }

    &.active {
      background: var(--secondary100);
      border: calc(var(--unit) / 8) solid var(--secondary100);
      color: var(--neutral800);
    }

    .content-switcher-item-icon {
      display: inline-flex;
      align-items: center;
      span.material-icons {
        font-size: calc(2 * var(--unit));
      }
    }

    .content-switcher-item-title {
      margin-left: var(--unit);
    }

    .badge,
    .badge_empty {
      margin-left: auto;
    }
  }

  .content-switcher-items_small,
  .content-switcher-items_small_divider {
    button.content-switcher-item-button {
      max-height: calc(4 * var(--unit));
      padding: calc(var(--unit) / 2) var(--unit);
      .content-switcher-item-title {
        margin-left: calc(var(--unit) / 2);
      }
    }
  }

  .bg04,
  &.bg04 {
    .content-switcher-items {
      border: calc(var(--unit) / 8) solid var(--primary400);
      &_divider,
      &_small_divider {
        .content-switcher-item-button:not(:last-child) {
          &::after {
            content: '';
            background-color: var(--neutral700);
          }
          &.active::after {
            content: none;
          }
        }
      }
    }
    .content-switcher-item-button {
      color: var(--neutral0);
      &:disabled {
        color: var(--neutral700);
      }
    }
  }
}
