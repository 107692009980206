.tag-ds {
  .shopping-cart {
    left: 0;
    top: 0;
    .overflow-menu-header > .overflow-menu-title {
      font-size: calc(3 * var(--unit));
      line-height: calc(4 * var(--unit));
    }
    .shopping-cart-items {
     .shopping-cart-item {
      position: relative;
      margin-top: var(--unit);
      border-radius: var(--unit);
      display: flex;
      align-items: center;
      padding: var(--unit);
      background-color: var(--neutral200);

      &:first-child {
        margin-top: 0;
      }

      &> img {
        border-radius: var(--unit);
      }
      & > .shopping-cart-item-details {
        margin-left: var(--unit);
        &>div:last-child {
          color: var(--neutral700);
        }
      }

      & > .shopping-cart-item-close {
        cursor: pointer;
        position: absolute;
        color: var(--neutral700);
        font-size: calc(2 * var(--unit));
        height: calc(2 * var(--unit));
        width: calc(2 * var(--unit));
        top: calc(2 * var(--unit));
        right: calc(2 * var(--unit));
      }
     }

     &_empty {
      margin: calc(4 * var(--unit)) calc(3 * var(--unit));
      margin-bottom: calc(5 * var(--unit));
      text-align: center;
     }
    }
    .shopping-cart-overall {
      margin-top: calc(3 * var(--unit));
      display: flex;
      justify-content: space-between;
      @include font(medium);
      font-weight: 500;
      
      &>div:last-child {
        color: var(--neutral700);
      }
    }

    .shopping-cart-detail {
      display: flex;
      justify-content: space-between;
      @include font(small);
      margin: var(--unit) 0;
      
      &>div:last-child {
        color: var(--neutral700);
      }
    }
  }
}