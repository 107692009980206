.tag-ds {
  .nps, &.nps {
    width: fit-content;
    .modal-header {
      margin-bottom: calc(3 * var(--unit));
      & .material-icons {
        width: 100%;
        text-align: center;
        font-size: calc(10 * var(--unit));
      }
    }
    
    @media (max-width: $mobile) {
      .modal-header {
        text-align: center;
      }
    }
  }

  &.modal.nps > .modal-header + .modal-body, 
  .modal.nps > .modal-header + .modal-body {
    margin-top: calc(9 * var(--unit));
    .slider-wrapper {
      height: calc(8 * var(--unit));
    }
  }
}