.tag-ds {
  .data-table-wrapper,
  &.data-table-wrapper {
    width: 100%;
    .data-table-top-toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin: 0 0 calc(3 * var(--unit));
      padding: 0 calc(2 * var(--unit));

      .tabs .tab-list {
        margin-bottom: 0;
      }

      .input-container {
        flex: 1;
        margin: 0 calc(4 * var(--unit)) 0 calc(5 * var(--unit));
      }
    }

    .data-table-action-bar {
      display: flex;
      align-items: center;
      width: 100%;
      height: calc(6 * var(--unit));
      border-radius: var(--unit);
      background: var(--neutral200);
      padding: var(--unit) calc(3 * var(--unit));
      margin-bottom: calc(3 * var(--unit));
      gap: calc(11 * var(--unit));
      .data-table-items-selected {
        @include font(base);
        white-space: nowrap;
      }
      .data-table-action-items {
        margin: 0 0 0 auto;
        display: inline-flex;
        gap: calc(3 * var(--unit));
        * {
          position: relative;
          &:last-child::before:not(:first-child::before) {
            position: absolute;
            left: calc(-1.5 * var(--unit));
            content: '';
            width: calc(var(--unit) / 8);
            height: 100%;
            background: var(--neutral400);
          }
        }
      }
    }
    .table-wrapper {
      margin-bottom: calc(3 * var(--unit));
    }
    .data-table-paginator {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  @media (max-width: $mobile) {
    .data-table-wrapper,
    &.data-table-wrapper {
      .data-table-top-toolbar {
        width: 100%;
        display: block;
        margin-bottom: calc(3 * var(--unit));
        padding: 0;

        > * {
          width: 100%;
          margin: 0 0 calc(3 * var(--unit));
        }

        .data-table-top-toolbar_middle {
          display: flex;
          gap: calc(3 * var(--unit));
          > button {
            flex: 1;
            margin: 0;
          }
        }

        .data-table-top-toolbar_middle + .input-container {
          margin: 0 0 calc(3 * var(--unit));
        }
      }

      .data-table-action-bar {
        width: 100%;
        height: calc(5 * var(--unit));
        padding: var(--unit) calc(2 * var(--unit));
        margin-bottom: calc(2 * var(--unit));
        overflow: hidden;
      }
      .table-wrapper {
        width: 100%;
        overflow-x: auto;
      }

      .data-table-paginator {
        justify-content: center;
      }
    }
  }
}
