%card {
  position: relative;
  height: fit-content;
  max-width: 80vw;
  min-height: calc(9 * var(--unit));
  @extend .shadow-elevation_2x;
  display: flex;
  flex-direction: column;
  flex: 1;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0;
  border-radius: var(--unit);
  padding: 0 calc(3 * var(--unit)) calc(3 * var(--unit));
  background: var(--neutral0);
  overflow: hidden;
  color: var(--neutral800);
  &:hover {
    @extend .shadow-elevation_3x;
  }
}

%card_vertical-img {
  position: relative;
  width: calc(100% + 6 * var(--unit));
  margin-left: calc(-3 * var(--unit));
  max-height: calc(25 * var(--unit));
  overflow-y: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .card-float-buttons {
    top: var(--unit);
    right: var(--unit);
  }
}

%card_horizontal-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 0;
  left: 0;
  border-top-left-radius: var(--unit);
  border-bottom-left-radius: var(--unit);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  width: calc(20 * var(--unit));
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .card-float-buttons {
    top: var(--unit);
    right: calc(100% - 19 * var(--unit));
  }
}

%card_horizontal-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

.tag-ds {
  button.button-card-icon {
    @extend %button-icon_interactive;

    border-radius: 50%;
    padding: 0;
    background: var(--neutral0);
    &:has(.material-icons:not(.left, .right)) {
      height: calc(5 * var(--unit));
      width: calc(5 * var(--unit));
    }
  }

  .card-float-buttons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--unit);
  }

  .card-float-icon {
    position: absolute;
    width: calc(4 * var(--unit));
    height: calc(4 * var(--unit));
    font-size: calc(4 * var(--unit));
    font-family: 'Material Icons Outlined';
  }

  .card-top {
    display: flex;
    align-items: center;
    margin: calc(2 * var(--unit)) 0 0;
    width: 100%;
    flex: 0 1 auto;
    color: var(--neutral800);

    .tag,
    .text-tag,
    .caption,
    caption {
      margin-left: auto;
      color: var(--neutral700);
    }

    .button-card-icon {
      margin-left: calc(2 * var(--unit));
    }
  }

  .card-figure {
    position: relative;
    width: 100%;
    flex: 0 1 auto;
    margin: calc(3 * var(--unit)) 0 0;
    @extend .figure;
    color: var(--accentColor);
    .card-float-icon {
      color: var(--neutral800);
    }
  }

  .card-header {
    width: 100%;
    flex: 0 1 auto;
    margin: calc(3 * var(--unit)) 0 0;
    border: 0;
    gap: calc(2 * var(--unit));
    background: inherit;
    padding: 0;
    color: var(--neutral800);

    .avatar + *,
    .avatar_big + *,
    .avatar_small + *,
    .avatar_xsmall + * {
      margin: calc(2 * var(--unit)) 0 0;
    }
    * + .subtitle {
      display: block;
      margin: var(--unit) 0 0;
    }
  }

  .card-top + .card-header {
    margin: calc(2 * var(--unit)) 0 0;
  }

  .card-figure + .card-header {
    margin: calc(1 * var(--unit)) 0 0;
  }

  .card-body {
    @include font(medium);
    margin: calc(3 * var(--unit)) 0 0;
    border: 0;
    width: 100%;
    flex: 1 1 auto;
    color: var(--neutral700);
    padding: 0;
    &:not(.material-icons),
    *:not(.material-icons) {
      font-family: var(--secondaryFontFamily);
    }

    .list-icon,
    .list-icon_small {
      color: var(--neutral800);
      .item-double {
        span:not(.icon-order) {
          color: var(--neutral700);
        }
      }
    }

    * + .list-icon,
    * + .list-icon_small {
      margin: calc(2 * var(--unit)) 0 0;
    }
  }

  .card-top + .card-body {
    margin: calc(2 * var(--unit)) 0 0;
  }
  .card-header + .card-body {
    margin: var(--unit) 0 0;
  }

  .card-footer {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: calc(3 * var(--unit));
    width: 100%;
    border: 0;
    margin: calc(4 * var(--unit)) 0 0;
    background: inherit;
    padding: 0;
    a {
      font-family: var(--secondaryFontFamily);
    }
  }

  .card,
  &.card {
    @extend %card;
    &_vertical,
    &_horizontal,
    &_selected,
    &_vertical_selected,
    &_horizontal_selected,
    &_figure,
    &_figure_selected,
    &_figure_accent,
    &_figure_accent_selected,
    &_teaser,
    &_teaser_selected,
    &_icon,
    &_icon_selected,
    &_masonry,
    &_masonry_selected,
    &_masonry_horizontal,
    &_masonry_horizontal_selected,
    &_map,
    &_map_selected {
      @extend %card;
    }
  }

  .card_selected,
  &.card_selected,
  .card_vertical_selected,
  &.card_vertical_selected,
  .card_horizontal_selected,
  &.card_horizontal_selected,
  .card_figure_selected,
  &.card_figure_selected,
  .card_figure_accent_selected,
  &.card_figure_accent_selected,
  .card_teaser_selected,
  &.card_teaser_selected,
  .card_icon_selected,
  &.card_icon_selected,
  .card_masonry_selected,
  &.card_masonry_selected,
  .card_masonry_horizontal_selected,
  &.card_masonry_horizontal_selected,
  .card_map_selected,
  &.card_map_selected {
    outline: calc(var(--unit) / 4) solid var(--secondary400);
  }

  //VERTICAL
  .card,
  &.card,
  .card_selected,
  &.card_selected,
  .card_vertical,
  &.card_vertical,
  .card_vertical_selected,
  &.card_vertical_selected {
    .card-img {
      @extend %card_vertical-img;
    }
    .card-img:only-child {
      margin-bottom: calc(-3 * var(--unit));
    }
  }

  //HORIZONTAL
  .card_horizontal,
  &.card_horizontal,
  .card_horizontal_selected,
  &.card_horizontal_selected {
    flex-direction: row;
    .card-img {
      @extend %card_horizontal-img;
    }
    .card_horizontal-content {
      @extend %card_horizontal-content;
    }

    .card-img ~ .card_horizontal-content {
      margin-left: calc(21 * var(--unit));
    }
  }

  //FIGURE
  .card_figure,
  &.card_figure,
  .card_figure_selected,
  &.card_figure_selected,
  .card_figure_accent,
  &.card_figure_accent,
  .card_figure_accent_selected,
  &.card_figure_accent_selected {
    padding: 0 calc(3 * var(--unit)) calc(4 * var(--unit));
    .card-figure {
      .card-float-icon {
        top: 0;
        right: 0;
      }
      &:only-child {
        width: fit-content;
        .card-float-icon {
          display: block;
          position: relative;
          margin: 0 auto var(--unit);
        }
      }
    }
    .card-figure:has(.card-float-icon) ~ .card-header,
    .card-figure:has(.card-float-icon) ~ .card-body {
      padding-right: calc(5 * var(--unit));
    }
  }

  .card_figure_accent,
  &.card_figure_accent,
  .card_figure_accent_selected,
  &.card_figure_accent_selected {
    background: var(--accentColor);
    .card-figure,
    .card-header,
    .card-body,
    .card-float-icon {
      color: var(--neutral800);
    }
  }

  //ICON
  .card_icon,
  &.card_icon,
  .card_icon_selected,
  &.card_icon_selected {
    flex-direction: row;
    padding: calc(3 * var(--unit)) calc(4 * var(--unit));
    .card-icon {
      width: calc(10 * var(--unit));
      height: calc(10 * var(--unit));
      padding: calc(2.5 * var(--unit));
      border-radius: 50%;
      background: var(--neutral100);
      margin: auto calc(4 * var(--unit)) auto 0;
      .material-icons {
        font-size: calc(5 * var(--unit));
      }
    }
    .card_icon-content {
      @extend %card_horizontal-content;
      margin: auto 0;
      .card-header {
        margin: 0;
      }
      .card-body {
        margin: 0;
      }
      .card-header + .card-body {
        margin: var(--unit) 0 0;
      }
      .card-footer {
        justify-content: flex-start;
        margin: calc(2 * var(--unit)) 0 0;
      }
    }
  }

  //TEASER
  .card_teaser,
  &.card_teaser,
  .card_teaser_selected,
  &.card_teaser_selected {
    .card-img {
      @extend %card_vertical-img;
    }
    .card-footer {
      > * {
        width: 100%;
      }
    }
  }

  //MASONRY
  .card_masonry,
  &.card_masonry,
  .card_masonry_selected,
  &.card_masonry_selected,
  .card_masonry_horizontal,
  &.card_masonry_horizontal,
  .card_masonry_horizontal_selected,
  &.card_masonry_horizontal_selected {
    padding: 0;

    .card-img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 100%;
        width: 100%;
      }
    }

    .card_masonry-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: calc(2 * var(--unit));
      z-index: 1;
      display: flex;
      flex-direction: column;
    }

    .card-top {
      justify-content: space-between;
      margin: 0;
      .button-card-icon {
        margin-left: auto;
        & + .button-card-icon {
          margin-left: var(--unit);
        }
      }
    }
    .card-header {
      margin: auto 0 0;
      padding: 0 calc(3 * var(--unit)) 0 var(--unit);
      color: var(--neutral0);
    }
    .card-body {
      flex: 0 1 auto;
      padding: 0 calc(3 * var(--unit)) 0 var(--unit);
      color: var(--neutral0);
    }

    .card-header + .card-body {
      margin-top: calc(2 * var(--unit));
    }

    .card-img.bgAccent ~ .card_masonry-content .card-header,
    .card-img.bgAccent ~ .card_masonry-content .card-body {
      color: var(--neutral800);
    }
  }

  .card_masonry,
  &.card_masonry,
  .card_masonry_selected,
  &.card_masonry_selected {
    height: calc(78 * var(--unit));
    min-width: calc(44 * var(--unit));
    width: fit-content;
    img {
      height: 100%;
    }
  }

  .card_masonry_horizontal,
  &.card_masonry_horizontal,
  .card_masonry_horizontal_selected,
  &.card_masonry_horizontal_selected {
    height: calc(26 * var(--unit));
    min-width: calc(94 * var(--unit));
    img {
      width: 100%;
    }
    .card-body {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  //LOCATION MAP

  .card_map,
  &.card_map,
  .card_map_selected,
  &.card_map_selected {
    min-height: calc(30 * var(--unit));
    .card-top {
      margin: 0;
      position: absolute;
      top: calc(2 * var(--unit));
      left: calc(2 * var(--unit));
      width: calc(100% - 4 * var(--unit));
    }
    .card-img {
      &:only-child {
        min-height: calc(30 * var(--unit));
        max-height: none;
        margin-bottom: calc(-3 * var(--unit));
      }
      @extend %card_vertical-img;
      height: calc(26 * var(--unit));
      position: relative;
      button {
        display: none;
      }
      &:hover {
        button {
          display: block;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 0;
          border-radius: calc(2 * var(--unit));
          padding: calc(var(--unit) / 2) calc(2 * var(--unit)) calc(var(--unit) / 2) var(--unit);
          @extend .tag;
          background: var(--neutral0);
          color: var(--neutral800);
          display: flex;
          align-items: center;
          gap: calc(var(--unit) / 2);
          min-width: auto;
          height: auto;
          .material-icons {
            font-size: calc(2 * var(--unit));
            margin-right: 0;
          }
        }
        &::before {
          content: '';
          background: linear-gradient(0deg, rgba(28, 48, 75, 0.24), rgba(28, 48, 75, 0.24));
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .card-header {
      display: flex;
      align-items: center;
      margin: calc(2 * var(--unit)) 0 0;
      .button-card-icon {
        margin-left: auto;
      }
    }
    .card-body {
      flex: 0 1 auto;
    }
    .card-header:has(.button-card-icon) ~ .card-body {
      padding-right: calc(6 * var(--unit));
    }
  }

  //DRAG AND DROP
  .card_drag-drop,
  &.card_drag-drop,
  .card_drag-drop_placeholder,
  &.card_drag-drop_placeholder,
  .card_drag-drop_completed,
  &.card_drag-drop_completed,
  .card_drag-drop_dropdown,
  &.card_drag-drop_dropdown {
    position: relative;
    min-width: calc(22 * var(--unit));
    max-width: calc(33 * var(--unit));
    min-height: calc(7 * var(--unit));
    display: flex;
    align-items: center;
    border: calc(var(--unit) / 8) solid var(--neutral200);
    border-radius: var(--unit);
    padding: calc(2 * var(--unit));
    background: var(--neutral0);
    margin-bottom: var(--unit);

    .card-header {
      @extend .subtitle;
    }
    .card-header {
      display: flex;
      flex: 1;
      margin: 0;
      cursor: default;
      gap: var(--unit);
    }
  }

  .card_drag-drop_completed,
  &.card_drag-drop_completed {
    .card-header::after {
      position: relative;
      margin-left: auto;
      content: '\e86c';
      font-family: 'Material Icons';
      font-size: calc(2 * var(--unit));
      color: var(--successColor);
    }
  }

  .card_drag-drop_dropdown,
  &.card_drag-drop_dropdown {
    display: block;
    height: auto;
    .dropdown {
      width: 100%;
      .dropdown-button {
        display: flex;
        align-items: center;
        .card-header {
          @include font(base);
        }
        .material-icons {
          color: var(--neutral800);
          margin-left: auto;
          font-size: calc(3 * var(--unit));
        }
      }
      .dropdown-menu {
        position: relative;
        width: 100%;
        margin-top: calc(2 * var(--unit));
        top: inherit;
        left: inherit;
        color: var(--neutral800);
      }
    }
  }

  .card_drag-drop[draggable='true'],
  .card_drag-drop_completed[draggable='true'] {
    @extend .shadow-elevation_1x;
    &:active,
    &.active {
      border: calc(var(--unit) / 8) solid var(--secondary400);
    }
    &::before {
      position: relative;
      margin-right: var(--unit);
      content: '\e945';
      font-family: 'Material Icons';
      font-size: calc(2 * var(--unit));
      cursor: grab;
      color: var(--neutral800);
    }

    .dropdown {
      margin: auto 0 0 auto;
      width: auto;
      .dropdown-button .material-icons {
        font-size: calc(2 * var(--unit));
      }
      .dropdown-menu {
        width: max-content;
        top: calc(100% + var(--unit) / 2);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .card_drag-drop_placeholder,
  &.card_drag-drop_placeholder {
    border: calc(var(--unit) / 8) dashed var(--neutral400);
    .card-header {
      @include font(base);
      color: var(--neutral700);
    }
  }
}
