%button {
  outline: 0;
  appearance: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--unit);
  min-width: calc(20 * var(--unit));

  & > svg.left,
  & > .material-icons.left,
  & > .spinner.left {
    margin-right: var(--unit);
  }

  & > svg.right,
  & > .material-icons.right,
  & > .spinner.right {
    margin-left: var(--unit);
  }
}

%button-primary-styles {
  color: var(--neutral0);
  background-color: var(--primaryColor);
  border: 0;
  &:hover {
    background-color: var(--primary600);
  }
  &:active {
    background-color: var(--primary400);
  }
  &:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--secondary300);
  }
  &:disabled {
    background-color: var(--primary100);
    pointer-events: none;
  }
}

%button-secondary-styles {
  color: var(--neutral800);
  background-color: var(--neutral0);
  border: calc(var(--unit) / 8) solid var(--primaryColor);
  &:hover {
    border: calc(var(--unit) / 8) solid var(--secondary400);
    background-color: var(--neutral0);
  }
  &:active {
    background-color: var(--secondary100);
  }

  &:hover:focus-visible,
  &:focus-visible:hover {
    border: calc(var(--unit) / 8) solid var(--secondary400);
  }

  &:focus-visible {
    border: calc(var(--unit) / 8) solid var(--neutral0);
    outline: calc(var(--unit) / 2) solid var(--secondary300);
  }
  &:disabled {
    color: var(--primary100);
    border: calc(var(--unit) / 8) solid var(--primary100);
    background-color: var(--neutral0);
    pointer-events: none;
  }
}

%button-cta-styles {
  color: var(--neutral800);
  background-color: var(--action500);
  border: 0;
  &:hover {
    background-color: var(--action600);
  }
  &:active {
    background-color: var(--action200);
  }

  &:hover:focus-visible,
  &:focus-visible:hover {
    border: calc(var(--unit) / 8) solid var(--action400);
  }

  &:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--action700);
  }
  &:disabled {
    background-color: var(--action100);
    pointer-events: none;
  }
}

%button-primary_success {
  background-color: var(--success100);
  color: var(--neutral800);
  pointer-events: none;
}

%button-primary_selected {
  background-color: var(--primary100);
  color: var(--neutral800);
  pointer-events: none;
}

%button-primary_loading {
  pointer-events: none;
  .spinner {
    width: calc(2 * var(--unit));
    height: calc(2 * var(--unit));
  }
}

%button-secondary_success {
  border: calc(var(--unit) / 8) solid var(--success100);
  pointer-events: none;
}

%button-secondary_selected {
  border: calc(var(--unit) / 8) solid var(--primary100);
  pointer-events: none;
}

%button-secondary_loading {
  .spinner {
    width: calc(2 * var(--unit));
    height: calc(2 * var(--unit));
  }
}

%button_large {
  @extend %button-font_large;
  @extend %icon;
  padding: calc(2 * var(--unit)) calc(3 * var(--unit));
  height: calc(7 * var(--unit));
  &:has(.material-icons:not(.left, .right)),
  &:has(.spinner:not(.left, .right)) {
    @extend %button-icon_large;
  }
}

%button_medium {
  @extend %button-font_medium;
  @extend %icon;
  padding: var(--unit) calc(3 * var(--unit));
  height: calc(5 * var(--unit));

  &:has(.material-icons:not(.left, .right)),
  &:has(.spinner:not(.left, .right)) {
    @extend %button-icon;
  }
}

%button_small {
  @extend %button-font_small;
  @extend %icon_small;
  padding: var(--unit) calc(2 * var(--unit));
  height: calc(4 * var(--unit));

  &:has(.material-icons:not(.left, .right)),
  &:has(.spinner:not(.left, .right)) {
    @extend %button-icon_small;
  }
}

%button-icon {
  min-width: calc(5 * var(--unit));
  height: calc(5 * var(--unit));
  padding: var(--unit);
}

%button-icon_small {
  min-width: calc(4 * var(--unit));
  height: calc(4 * var(--unit));
  padding: var(--unit);
}

%button-icon_large {
  min-width: calc(7 * var(--unit));
  height: calc(7 * var(--unit));
  padding: calc(2 * var(--unit));
}

%button-icon_interactive {
  @extend %button;
  @extend %icon;
  background-color: inherit;
  color: var(--neutral800);
  border: calc(var(--unit) / 8) solid transparent;

  min-width: auto;
  width: calc(4 * var(--unit));
  height: calc(4 * var(--unit));
  padding: calc(var(--unit) / 2);

  &:has(.material-icons:not(.left, .right)) {
    min-width: auto;
    width: calc(4 * var(--unit));
    height: calc(4 * var(--unit));
    padding: calc(var(--unit) / 2);
  }

  &:hover {
    background-color: var(--neutral100);
    border: calc(var(--unit) / 8) solid var(--secondary400);
  }

  &:active {
    background-color: var(--neutral200);
  }

  &:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--secondary300);
  }

  &:disabled {
    color: var(--neutral500);
    pointer-events: none;
  }
}

%icon_success {
  svg,
  .material-icons {
    color: var(--successColor);
  }
}

%icon_selected {
  svg,
  .material-icons {
    color: var(--neutral800);
  }
}

%icon {
  svg,
  .material-icons {
    font-size: calc(3 * var(--unit));
  }
}

%icon_small {
  svg,
  .material-icons {
    font-size: calc(2 * var(--unit));
  }
}

@mixin button($type: 'primary') {
  @extend %button;
  @extend %button_medium;
  @if $type == 'secondary' {
    @extend %button-secondary-styles;

    &.success,
    &.selected,
    &.loading {
      @extend %button-secondary-styles;
    }

    &_loading,
    &_success,
    &_selected {
      @extend %button;
      @extend %button-secondary-styles;
      @extend %button_medium;
    }
    &_loading {
      @extend %button-secondary_loading;
    }
    &_success {
      @extend %button-secondary_success;
      @extend %icon_success;
    }
    &_selected {
      @extend %button-secondary_selected;
      @extend %icon_selected;
    }

    &_small,
    &_large {
      @extend %button;
      @extend %button-secondary-styles;

      &_loading,
      &_success,
      &_selected {
        @extend %button;
        @extend %button-secondary-styles;
      }
      &_loading {
        @extend %button-secondary_loading;
      }
      &_success {
        @extend %button-secondary_success;
        @extend %icon_success;
      }
      &_selected {
        @extend %button-secondary_selected;
      }
      &.selected {
        @extend %button-secondary-styles;
        @extend %button-secondary_selected;
        @extend %icon_selected;
      }
      &.success {
        @extend %button-secondary_success;
        @extend %button-secondary-styles;
        @extend %icon_success;
      }
    }
    &_small {
      @extend %button_small;
      &_loading,
      &_success,
      &_selected {
        @extend %button_small;
      }
    }

    &_large {
      @extend %button_large;
      &_loading,
      &_success,
      &_selected {
        @extend %button_large;
      }
    }

    &.success {
      @extend %button-secondary_success;
      @extend %icon_success;
    }
    &.selected {
      @extend %button-secondary_selected;
      @extend %icon_selected;
    }
    &.loading {
      @extend %button-secondary_loading;
    }
  } @else if ($type == 'cta') {
    @extend %button-cta-styles;

    &.loading {
      @extend %button-cta-styles;
    }

    &_loading {
      @extend %button;
      @extend %button-cta-styles;
      @extend %button_medium;
    }
    &_loading {
      @extend %button-primary_loading;
    }

    &_small,
    &_large {
      @extend %button;
      @extend %button-cta-styles;
      .material-icons {
        color: var(--neutral0);
      }

      &_loading {
        @extend %button;
        @extend %button-cta-styles;
      }
      &_loading {
        @extend %button-primary_loading;
      }
    }
    &_small {
      @extend %button_small;
      &_loading {
        @extend %button_small;
      }
    }

    &_large {
      @extend %button_large;
      &_loading {
        @extend %button_large;
      }
    }
    &.loading {
      @extend %button-primary_loading;
    }
  } @else {
    @extend %button-primary-styles;

    &_loading,
    &_success,
    &_selected {
      @extend %button;
      @extend %button-primary-styles;
      @extend %button_medium;
    }
    &_loading {
      @extend %button-primary_loading;
    }
    &_success {
      @extend %button-primary_success;
      @extend %icon_success;
    }
    &_selected {
      @extend %button-primary_selected;
      @extend %icon_selected;
    }

    &_small,
    &_large {
      @extend %button;
      @extend %button-primary-styles;

      &_loading,
      &_success,
      &_selected {
        @extend %button;
        @extend %button-primary-styles;
      }
      &_loading {
        @extend %button-primary_loading;
      }
      &_success {
        @extend %button-primary_success;
        @extend %icon_success;
      }
      &_selected {
        @extend %button-primary_selected;
      }
    }
    &_small {
      @extend %button_small;
      &_loading,
      &_success,
      &_selected {
        @extend %button_small;
      }
    }

    &_large {
      @extend %button_large;
      &_loading,
      &_success,
      &_selected {
        @extend %button_large;
      }
    }

    &.success {
      @extend %button-primary_success;
      @extend %icon_success;
    }
    &.selected {
      @extend %button-primary_selected;
    }
    &.loading {
      @extend %button-primary_loading;
    }
  }

  &.small {
    @extend %button_small;
  }
  &.large {
    @extend %button_large;
  }
}

.tag-ds {
  .button,
  &.button,
  .button-primary,
  &.button-primary {
    @include button('primary');
  }
  .button-secondary,
  &.button-secondary {
    @include button('secondary');
  }

  .button-cta,
  &.button-cta {
    @include button('cta');
    .material-icons {
      color: var(--neutral0);
    }
  }

  input[type='button'],
  input[type='submit'],
  button {
    @extend %button;
    @extend %button-primary-styles;
    @extend %button_medium;
    &.small {
      @extend %button_small;
    }
    &.large {
      @extend %button_large;
    }
    &.success {
      @extend %button-primary_success;
      @extend %icon_success;
    }
    &.selected {
      @extend %button-primary_selected;
    }
    &.loading {
      @extend %button-primary_loading;
    }

    &.button,
    &.button-primary {
      @include button('primary');
    }
    &.button-secondary {
      @include button('secondary');
    }
    &.button-cta {
      @include button('cta');
      .material-icons {
        color: var(--neutral0);
      }
    }
  }

  input[type='button'].tag-ds,
  input[type='submit'].tag-ds,
  button.tag-ds {
    @extend %button;
    @extend %button-primary-styles;
    @extend %button_medium;
    &.small {
      @extend %button_small;
    }
    &.large {
      @extend %button_large;
    }
    &.success {
      @extend %button-primary_success;
    }
    &.selected {
      @extend %button-primary_selected;
    }
    &.loading {
      @extend %button-primary_loading;
    }

    &.button,
    &.button-primary {
      @include button('primary');
    }
    &.button-secondary {
      @include button('secondary');
    }
    &.button-cta {
      @include button('cta');
      .material-icons {
        color: var(--neutral0);
      }
    }
  }
}
