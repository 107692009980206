.tag-ds {
  .cookie-message, &.cookie-message {
    max-height: 100vh;
    padding: calc(3 * var(--unit)) calc(5 * var(--unit));
    @extend .shadow-elevation_1x;
    position: fixed;
    bottom: 0;
    overflow-y: auto;

    h4 { margin-bottom: calc(2 * var(--unit));}

    .cookie-message-right > button {
      width: calc(42 * var(--unit));
    }

    .cookie-message-right button:first-child {
      margin-top: 0;
      margin-bottom: calc(3 * var(--unit));
    }
    

    .cookie-message-container {
      display: flex;
      gap: calc(4 * var(--unit));
      align-items: center;
    }
    
    
    .cookie-message-body:has(.cookie-message-footer) {
      @include font(base);
      margin-bottom: calc(4 * var(--unit));
    }

  }

  .cookie-message-back {
    margin-bottom: calc(2 * var(--unit));

    display: flex;
    align-items: center;
    gap: calc(2 * var(--unit));

    &>.cookie-message-back-button {
      @extend %button-icon_interactive;
      margin-top: 0;
    }

    .cookie-message-back-helper {
      text-transform: uppercase;
    }
  }
}
  @media (max-width: $mobile) {
    .cookie-message-container {
      flex-direction: column;
    }
    .cookie-message-right, .cookie-message-right button { width: 100%;}
    .cookie-message {
      max-height: 100vh;
      padding: calc(2 * var(--unit)) calc(2.5 * var(--unit));
    }
}
