.adecco,
.tag-ds .adecco,
.adecco .tag-ds {
  --accent100: #ffd3d3;
  --accent200: #fda8a8;
  --accent300: #f38787;
  --accent400: #ed4f4f;
  --accent500: #da291c;
  --accent600: #bf0d3e;
  --accent700: #782f40;

  --action100: #ccf2ff;
  --action200: #99e6ff;
  --action300: #33cdff;
  --action400: #009acc;
  --action500: #107b9e;
  --action600: #0e6987;
  --action700: #004d66;

  --accentColor: var(--accent500);
  .accent-color {
    background: var(--accentColor);
  }
  --actionColor: var(--action500);
  .action-color {
    background: var(--actionColor);
  }
}
