.tag-ds {
  .login {
    max-width: calc(78 * var(--unit));
    max-height: unset;

    .modal-header {
      text-align: center;
      margin-bottom: calc(5 * var(--unit));
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: calc(3 * var(--unit));

      & .input-icon-box {
        width: calc(3 * var(--unit));
        cursor: pointer;
        user-select: none;
      }
    }

    .modal-footer {
      flex-direction: column;
      gap: calc(2 * var(--unit));
      button {
        width: 100%;
      }

      .button-secondary_large,
      .button-secondary.large {
        border-color: transparent;

        &:hover {
          border-color: var(--secondary400);
        }

        &:focus {
          background-color: var(--neutral100);
        }

        &:focus-visible {
          outline: calc(var(--unit) / 2) solid var(--secondary400);
        }
      }

      .login-options {
        margin-top: calc(4 * var(--unit));
        display: inline;
        & > a {
          padding-right: calc(3 * var(--unit));
          padding-left: calc(3 * var(--unit));
          border-right: 1px solid var(--neutral200);
        }

        & > a:first-child {
          padding-left: 0;
        }
        & > a:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
      & > .login-language {
        margin-top: calc(5 * var(--unit));
      }

      @media (max-width: $mobile) {
        .login-language {
          width: auto;
        }
      }

      @media (max-width: $mobile) {
        text-align: center;
      }
    }
  }
}
