%paginator-page {
  @extend %button-icon_interactive;
  border-radius: 50%;
  border-color: transparent;
  min-width: auto;
  color: var(--neutral700);
  height: calc(3 * var(--unit));
  width: calc(3 * var(--unit));
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: inherit;
  }
  &:hover,
  &:focus-visible {
    color: var(--neutral800);
  }
}

.tag-ds {
  .paginator,
  &.paginator {
    @include font(small);
    display: flex;
    gap: var(--unit);
  }

  .paginator-truncate {
    margin: 0;
    display: inline-block;
    color: var(--neutral700);
  }

  button.paginator-page {
    @extend %paginator-page;
  }

  button.paginator-page_active {
    @extend %paginator-page;
    background-color: var(--accent500);
    color: var(--neutral800);

    &:focus-visible {
      border: calc(var(--unit) / 8) solid var(--accent500);
    }

    &.disabled {
      background-color: var(--neutral200);
    }

    &:disabled {
      background-color: var(--neutral200);
    }
  }

  .paginator-collapsed {
    display: flex;
    gap: var(--unit);
    align-items: center;
    color: var(--neutral800);
  }

  .paginator-collapsed-dropdown {
    width: calc(11 * var(--unit));
    height: calc(5 * var(--unit));
  }

  button.paginator-icon {
    @extend %button-icon_interactive;
    &:has(.material-icons:not(.right, .left)) {
      height: calc(3 * var(--unit));
      width: calc(3 * var(--unit));
    }
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .material-icons {
      user-select: none;
      font-size: calc(2 * var(--unit));
      color: var(--neutral700);
    }

    &:hover {
      border: 0;
    }

    &.disabled {
      & > span {
        color: var(--neutral500);
      }
    }

    &:disabled {
      & > span {
        color: var(--neutral500);
      }
    }
  }
}
