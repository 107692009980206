.tag-ds {
  .tree-container,
  &.tree-container {
    width: 100%;
    .tree-element,
    &.tree-element {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      padding-left: calc(3 * var(--unit));
      margin-bottom: var(--unit);
      @include font(medium);
      text-transform: none;
      border: 0;
      border-left: calc(var(--unit) / 4) solid transparent;
      background-color: transparent;
      border-radius: 0;
      height: auto;
      width: 100%;

      .tree-li {
        display: flex;
        align-items: center;
        padding-right: calc(2 * var(--unit));
      }

      & .list-icon {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
      }

      .tree-element-icon-right {
        margin-left: auto;
        font-size: calc(2 * var(--unit));
      }

      & > li {
        color: var(--neutral800);
        text-align: left;
        width: 100%;
      }

      &:hover {
        & > li {
          color: var(--secondary600);
        }
        background-color: transparent;
        border: 0;
        border-left: calc(var(--unit) / 4) solid transparent;
      }

      &:active {
        border: 0;
        border-left: calc(var(--unit) / 4) solid var(--accent500);
        & > li {
          color: var(--secondary700);
        }
      }

      &:focus {
        background-color: var(--neutral100);
        border: 0;
        border-left: calc(var(--unit) / 4) solid var(--accent500);
      }

      &:focus-visible {
        outline: calc(var(--unit) / 2) solid var(--secondary300);
      }

      &:disabled {
        background-color: transparent;
        border: 0;
        border-left: calc(var(--unit) / 4) solid transparent;
        & > li {
          color: var(--neutral500);
        }
      }
    }

    .tree-element-item {
      padding-left: var(--unit);
    }
  }
}
