.lhh,
.tag-ds .lhh,
.lhh .tag-ds {
  --accent100: #f5eaf9;
  --accent200: #a57db3;
  --accent300: #752386;
  --accent400: #611e6f;
  --accent500: #4f185a;
  --accent600: #391141;
  --accent700: #290c2f;

  --secondary100: #f2d0e1;
  --secondary200: #e5a1c4;
  --secondary300: #db74a7;
  --secondary400: #d24a8b;
  --secondary500: #dc0a6f;
  --secondary600: #b90b5f;
  --secondary700: #870242;

  --secondaryColor: var(--secondary500);
  .secondary-color {
    background: var(--secondaryColor);
  }
  --accentColor: var(--accent500);
  .accent-color {
    background: var(--accentColor);
  }

  --fontFamily: 'Noto Serif', sans-serif;
}
