.adecco,
.adeccofaq,
.adeccofull,
.amazon {
  --brand-primary: #ef2e24;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --banner-background: #fff;
  --logo: url('~@resources/brands/Adecco/Logo.svg');
  --logo-height: 28px;
  --logo-width: 112px;
  --logo-margin: 16px 12px;
  --title: #000;
  --progressbar-background: #adadb5;
  --progressbar-value: #5c6573;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/Adecco/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #fff;
  --widget-header: linear-gradient(180deg, #da291c 0%, #bf0d3e 100%);
  --widget-text: #fff;
}

.adecco,
.amazon {
  --background-url: url('~@resources/brands/Adecco/Background.png');
  --chat-avatar: url('~@resources/brands/Adecco/chatbotAvatar.svg');
}

.adeccofaq,
.adeccofull {
  --background-url: url('~@resources/brands/AdeccoFAQ/Background.png');
  --chat-avatar: url('~@resources/brands/Adecco/chatbotAvatar.svg');
  --progressbar-display: none;
}

.adeccomedical {
  --brand-primary: #ef2e24;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/AdeccoMedical/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/AdeccoMedical/Logo.svg');
  --logo-height: 40px;
  --logo-width: 96px;
  --logo-margin: 12px 0px 12px 16px;
  --title: #000;
  --progressbar-background: #ccc;
  --progressbar-value: #ef2e24;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/Adecco/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #ef2e24;
  --widget-header: #ef2e24;
  --widget-text: #fff;
}

.adeccogroup {
  --brand-primary: #51c7c1;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(81, 199, 193, 0.52));
  --background-url: url('~@resources/brands/AdeccoGroup/Background.png');
  --banner-background: #fff;
  --logo: url('~@resources/brands/AdeccoGroup/Logo.svg');
  --logo-height: 40px;
  --logo-width: 172px;
  --logo-margin: 18px 4px 8px 16px;
  --title: #000;
  --progressbar-background: #ccc;
  --progressbar-value: #51c7c1;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/AdeccoGroup/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #51c7c1;
  --widget-header: #51c7c1;
  --widget-text: #1c304b;
}

.akkodis {
  --brand-primary: #ffc133;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(238, 246, 19, 0.3));
  --background-url: url('~@resources/brands/Akkodis/Background.png');
  --banner-background: #fff;
  --logo: url('~@resources/brands/Akkodis/Logo.svg');
  --logo-height: 16px;
  --logo-width: 120px;
  --logo-margin: 28px 8px 20px 12px;
  --title: #000;
  --progressbar-background: #ccc;
  --progressbar-value: #ffc133;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/Akkodis/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #ffc133;
  --widget-header: #ffc133;
  --widget-text: #1c304b;
}

.whitelabel {
  --brand-primary: #51c7c1;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(18, 18, 18, 0.52));
  --banner-background: #fff;
  --logo-height: 40px;
  --logo-width: 0;
  --logo-margin: 18px 4px 8px 16px;
  --title: #000;
  --progressbar-background: #ccc;
  --progressbar-value: #51c7c1;
  --progressbar-display: none;
  --chat-avatar: url('~@resources/brands/AdeccoGroup/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #51c7c1;
  --widget-header: #51c7c1;
  --widget-text: #1c304b;
}

.ceoforonemonth {
  --brand-primary: #273446;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/CEOForOneMonth/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/CEOForOneMonth/Logo.svg');
  --logo-height: 56px;
  --logo-width: 80px;
  --logo-margin: 4px 0 8px 8px;
  --title: #273446;
  --progressbar-background: #ccc;
  --progressbar-value: #273446;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/CEOForOneMonth/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #273446;
  --widget-header: #273446;
  --widget-text: #fff;
}

.disag {
  --brand-primary: #ad0f28;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/DISAG/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/DISAG/Logo.svg');
  --logo-height: 64px;
  --logo-width: 120px;
  --logo-margin: 0 8px 0 0;
  --title: #ad0f28;
  --progressbar-background: #ccc;
  --progressbar-value: #ad0f28;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/DISAG/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #ad0f28;
  --widget-header: #ad0f28;
  --widget-text: #fff;
}

.disconsulting {
  --brand-primary: #750106;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/DISConsulting/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/DISConsulting/Logo.svg');
  --logo-height: 36px;
  --logo-width: 180px;
  --logo-margin: 16px 8px 16px 16px;
  --title: #750106;
  --progressbar-background: #ccc;
  --progressbar-value: #750106;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/DISConsulting/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #750106;
  --widget-header: #750106;
  --widget-text: #fff;
}

.europassistance,
.focore {
  --brand-primary: #00239c;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/EuropAssistance/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/EuropAssistance/Logo.svg');
  --logo-height: 56px;
  --logo-width: 92px;
  --logo-margin: 8px 0;
  --title: #00239c;
  --progressbar-background: #ccc;
  --progressbar-value: #00239c;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/EuropAssistance/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #00239c;
  --widget-header: #00239c;
  --widget-text: #fff;
}

.lhh {
  --brand-primary: #4f185a;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/LHH/Background.png');
  --banner-background: #fff;
  --logo: url('~@resources/brands/LHH/Logo.svg');
  --logo-height: 24px;
  --logo-width: 64px;
  --logo-margin: 24px 8px 16px 24px;
  --title: #4f185a;
  --progressbar-background: #ccc;
  --progressbar-value: #4f185a;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/LHH/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #4f185a;
  --widget-header: #4f185a;
  --widget-text: #fff;
}

.pontoon {
  --brand-primary: #000;
  --bot-background: #f1f3f6;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(18, 18, 18, 0.52));
  --background-url: url('~@resources/brands/Pontoon/Background.png');
  --banner-background: #ffffff;
  --logo: url('~@resources/brands/Pontoon/Logo.svg');
  --logo-height: 20px;
  --logo-width: 120px;
  --logo-margin: 32px 8px 20px 24px;
  --title: #000;
  --progressbar-background: #ccc;
  --progressbar-value: #000;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/Pontoon/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #000;
  --widget-header: #000;
  --widget-text: #fff;
}

.proserv {
  --brand-primary: #001d67;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/ProServ/Background.png');
  --banner-background: #ffd200;
  --logo: url('~@resources/brands/ProServ/Logo.svg');
  --logo-height: 32px;
  --logo-width: 132px;
  --logo-margin: 14px 8px 12px 16px;
  --title: #001d67;
  --progressbar-background: #ccc;
  --progressbar-value: #001d67;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/ProServ/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #001d67;
  --widget-header: #001d67;
  --widget-text: #fff;
}

.qapa {
  --brand-primary: #0f056b;
  --bot-background: #f9f8fc;
  --bot-shadow: drop-shadow(2px 5px 20px rgba(68, 0, 0, 0.52));
  --background-url: url('~@resources/brands/QAPA/Background.png');
  --banner-background: #f9f8fc;
  --logo: url('~@resources/brands/QAPA/Logo.svg');
  --logo-height: 56px;
  --logo-width: 80px;
  --logo-margin: 8px 0px 8px 16px;
  --title: #0f056b;
  --progressbar-background: #ccc;
  --progressbar-value: #0f056b;
  --progressbar-display: block;
  --chat-avatar: url('~@resources/brands/QAPA/chatbotAvatar.svg');
  --autocomplete-banner-text-color: #0f056b;
  --widget-header: #0f056b;
  --widget-text: #fff;
}
