.tag-ds {
  .sidepanel,
  &.sidepanel {
    height: 100%;

    .input-container {
      margin-bottom: calc(4 * var(--unit));
    }

    .tree-element {
      border-left: 0;
      padding: var(--unit) 0 var(--unit) calc(3 * var(--unit));
      & > li {
        color: var(--neutral700);
      }

      &:hover,
      &:active {
        border-left: 0;
      }
      &:focus {
        background-color: transparent;
        font-weight: 500;
        border-left: 0;
        & > li {
          color: var(--neutral800);
        }
      }

      & + .tree-element-item {
        position: relative;
        .tree-element {
          padding: 0 0 0 calc(3 * var(--unit));
          &:focus {
            & > li {
              color: var(--secondary700);
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: calc(-1 * var(--unit));
          left: 0;
          width: 100%;
          height: calc(var(--unit) / 8);
          background-color: var(--neutral200);
        }
      }
    }
  }
}
