@mixin font($name) {
  font-weight: 400;
  @if $name == large {
    font-size: var(--fontSizeLarge);
    line-height: var(--lineHeightLarge);
  } @else if $name == base {
    font-size: var(--fontSizeBase);
    line-height: var(--lineHeightBase);
  } @else if $name == small {
    font-size: var(--fontSizeSmall);
    line-height: var(--lineHeightSmall);
  } @else {
    font-size: var(--fontSizeMedium);
    line-height: var(--lineHeightMedium);
  }
}
.tag-ds {
  --fontSizeLarge: calc(2.5 * var(--unit));
  --lineHeightLarge: calc(4 * var(--unit));

  --fontSizeBase: calc(2 * var(--unit));
  --lineHeightBase: calc(3 * var(--unit));

  --fontSizeMedium: calc(1.75 * var(--unit));
  --lineHeightMedium: calc(3 * var(--unit));

  --fontSizeSmall: calc(1.5 * var(--unit));
  --lineHeightSmall: calc(2 * var(--unit));

  .figure,
  &.figure {
    font-weight: 300;
    font-size: calc(10 * var(--unit));
    line-height: calc(11 * var(--unit));
  }

  .hero,
  &.hero {
    font-weight: 400;
    font-size: calc(8 * var(--unit));
    line-height: calc(9 * var(--unit));
  }

  h1,
  .h1,
  &.h1 {
    font-weight: 400;
    font-size: calc(6 * var(--unit));
    line-height: calc(7 * var(--unit));
    margin: 0;
  }

  h2,
  .h2,
  &.h2 {
    font-weight: 400;
    font-size: calc(5 * var(--unit));
    line-height: calc(6 * var(--unit));
    margin: 0;
  }

  h3,
  .h3,
  &.h3 {
    font-weight: 400;
    font-size: calc(4 * var(--unit));
    line-height: calc(5 * var(--unit));
    margin: 0;
  }

  h4,
  .h4,
  &.h4 {
    font-weight: 400;
    font-size: calc(3 * var(--unit));
    line-height: calc(4 * var(--unit));
    margin: 0;
  }

  h5,
  .h5,
  &.h5 {
    font-weight: 400;
    font-size: calc(2 * var(--unit));
    line-height: calc(3 * var(--unit));
    margin: 0;
  }

  h6,
  .h6,
  &.h6 {
    font-weight: 400;
    font-size: calc(1.5 * var(--unit));
    line-height: calc(3 * var(--unit));
    margin: 0;
  }

  .subtitle,
  &.subtitle {
    font-weight: 500;
    font-size: calc(2 * var(--unit));
    line-height: calc(3 * var(--unit));
  }

  %small-title,
  .small-title,
  &.small-title {
    font-weight: 500;
    font-size: calc(1.75 * var(--unit));
    line-height: calc(3 * var(--unit));
  }

  .large,
  &.large {
    @include font(large);
  }

  .base,
  &.base {
    @include font(base);
  }

  .medium,
  &.medium {
    @include font(medium);
  }

  small,
  .small,
  &.small {
    @include font(small);
  }

  %button-font {
    &_large,
    &_medium {
      @include font(medium);
      font-weight: 500;
      text-transform: uppercase;
    }
    &_small {
      @include font(small);
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  %caption-font,
  caption,
  .caption,
  &.caption {
    @include font(small);
    text-transform: uppercase;
  }

  %link-font {
    &_big {
      font-weight: 500;
      font-size: calc(1.75 * var(--unit));
      line-height: calc(2 * var(--unit));
      text-transform: uppercase;
    }
    &_small {
      font-size: calc(1.5 * var(--unit));
      line-height: calc(2 * var(--unit));
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  %tag-font,
  .tag,
  &.tag {
    font-weight: 500;
    font-size: calc(1.25 * var(--unit));
    line-height: calc(2 * var(--unit));
    text-transform: uppercase;
  }

  @media (max-width: $mobile) {
    .figure,
    &.figure {
      font-size: calc(8 * var(--unit));
      line-height: calc(9 * var(--unit));
    }

    .hero,
    &.hero {
      font-size: calc(7 * var(--unit));
      line-height: calc(8 * var(--unit));
    }

    h1,
    .h1,
    &.h1 {
      font-size: calc(5 * var(--unit));
      line-height: calc(6 * var(--unit));
    }

    h2,
    .h2,
    &.h2 {
      font-size: calc(4 * var(--unit));
      line-height: calc(5 * var(--unit));
    }

    h3,
    .h3,
    &.h3 {
      font-size: calc(3 * var(--unit));
      line-height: calc(4 * var(--unit));
    }

    h4,
    .h4,
    &.h4 {
      font-size: calc(2.5 * var(--unit));
      line-height: calc(4 * var(--unit));
    }
  }
}

h1.tag-ds {
  font-weight: 400;
  font-size: calc(6 * var(--unit));
  line-height: calc(7 * var(--unit));
  margin: 0;
}

h2.tag-ds {
  font-weight: 400;
  font-size: calc(5 * var(--unit));
  line-height: calc(6 * var(--unit));
  margin: 0;
}

h3.tag-ds {
  font-weight: 400;
  font-size: calc(4 * var(--unit));
  line-height: calc(5 * var(--unit));
  margin: 0;
}

h4.tag-ds {
  font-weight: 400;
  font-size: calc(3 * var(--unit));
  line-height: calc(4 * var(--unit));
  margin: 0;
}

h5.tag-ds {
  font-weight: 400;
  font-size: calc(2 * var(--unit));
  line-height: calc(3 * var(--unit));
  margin: 0;
}

h6.tag-ds {
  font-weight: 400;
  font-size: calc(1.5 * var(--unit));
  line-height: calc(3 * var(--unit));
  margin: 0;
}

caption.tag-ds {
  font-weight: 400;
  font-size: calc(1.5 * var(--unit));
  line-height: calc(2 * var(--unit));
  text-transform: uppercase;
}

small.tag-ds {
  font-size: var(--fontSizeSmall);
  font-weight: 400;
  line-height: var(--lineHeightSmall);
}
