.app {
  overflow-x: hidden;

  * > .material-icons {
    font-family: 'Material Icons' !important;
  }

  * > :not(.material-icons) {
    font-family: 'Nunito', sans-serif !important;
  }

  * > code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  * > button {
    text-transform: none !important;
    & > div {
      white-space: normal !important;
    }
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #808080;
  }
}

.error {
  margin: 20px;

  .logo {
    background: url('~@resources/error.png');
    height: 400px;
    width: 400px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
