%calendar-btn-day {
  font-weight: 400;
  position: relative;
  background: inherit;
  border: 0;
  border-radius: 0;
  padding: 0;
  color: var(--neutral800);
  min-width: auto;
  width: calc(6 * var(--unit));
  height: calc(6 * var(--unit));
  text-align: center;

  &:not(.active, .active_first, .active_last, .active_all):hover {
    outline: 0;
    border: calc(var(--unit) / 8) solid var(--secondary400);
    border-radius: 50%;
  }
  &:focus-visible {
    outline: calc(var(--unit) / 2) solid var(--secondary300);
    border-radius: 50%;
  }

  &:disabled {
    color: var(--primary100);
    pointer-events: none;
  }
}

.tag-ds {
  .calendar,
  &.calendar {
    @extend .shadow-elevation_1x;
    width: calc(46 * var(--unit));
    max-height: calc(45 * var(--unit));
    border-radius: var(--unit);
    background: var(--neutral0);
    padding: calc(2 * var(--unit));
    overflow: hidden;
  }

  .calendar-navigation {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(3 * var(--unit));
  }

  button.calendar-navigation-btn_prev,
  button.calendar-navigation-btn_next {
    @extend %button-icon_interactive;
  }

  button.calendar-navigation-label,
  .calendar-navigation-label {
    @include font(base);
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: auto;
    max-height: none;
    gap: var(--unit);
    .material-icons {
      font-size: calc(3 * var(--unit));
    }

    background: none;
    color: var(--neutral800);
    border: 0;
    text-transform: none;
    &:hover,
    &:active,
    &:focus-visible {
      background: none;
      color: var(--neutral800);
      border: 0;
      outline: 0;
    }
  }

  .calendar-month-weekdays {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(2 * var(--unit));
    margin-bottom: calc(2 * var(--unit));
  }

  .calendar-month-weekdays div {
    text-decoration: none;
    flex-basis: calc(100% / 7);
    max-width: calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;

    abbr {
      text-decoration: none;
      color: var(--neutral700);
    }
  }

  .calendar-month-days,
  .calendar-years {
    display: flex;
    flex-wrap: wrap;
  }

  .calendar-years {
    width: calc(100% + var(--unit));
    height: calc(34 * var(--unit));
    overflow-y: auto;
  }

  button.calendar-day,
  button.calendar-day_today,
  button.calendar-day_selected,
  button.calendar-day_today_selected {
    @extend %calendar-btn-day;
  }

  button.calendar-year,
  button.calendar-year_selected {
    @extend %calendar-btn-day;
    width: calc(10.5 * var(--unit));
    height: calc(6 * var(--unit));
    border-radius: calc(12 * var(--unit));
    &:not(.active, .active_first, .active_last, .active_all):hover {
      border-radius: calc(12 * var(--unit));
    }
    &:focus-visible {
      border-radius: calc(12 * var(--unit));
    }
  }

  button.calendar-day_today,
  button.calendar-day_today_selected {
    &::after {
      position: absolute;
      content: '';
      top: calc(100% - 1.5 * var(--unit));
      width: 4px;
      height: 4px;
      background: var(--secondary400);
      border-radius: 50%;
    }
  }

  button.calendar-day_selected,
  button.calendar-day_today_selected {
    background: var(--secondary100);
    border-radius: 50%;
    z-index: 1;
  }

  button.calendar-year_selected {
    background: var(--secondary100);
  }

  button.calendar-day.active,
  button.calendar-day.active_first,
  button.calendar-day.active_last,
  button.calendar-day.active_all,
  button.calendar-day_today.active,
  button.calendar-day_today.active_first,
  button.calendar-day_today.active_last,
  button.calendar-day_today.active_all {
    background: var(--neutral100);
    &:disabled {
      color: var(--neutral600);
    }

    &:hover {
      outline: 0;
      border: 0;
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border: calc(var(--unit) / 8) solid var(--secondary400);
        background: transparent;
        border-radius: 50%;
        top: 0;
        left: 0;
      }
    }
  }

  button.calendar-day.active_first,
  button.calendar-day_today.active_first {
    span::before {
      content: '';
      background-color: var(--neutral100);
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -50%;
    }
  }

  button.calendar-day.active_last,
  button.calendar-day_today.active_last {
    span::before {
      content: '';
      background-color: var(--neutral100);
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -50%;
    }
  }

  button.calendar-day.active_all,
  button.calendar-day_today.active_all {
    span::before {
      content: '';
      background-color: var(--neutral100);
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -50%;
    }
    span::after {
      content: '';
      background-color: var(--neutral100);
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -50%;
    }
  }

  .datepicker-wrapper,
  &.datepicker-wrapper {
    &.dropdown {
      background: inherit;
    }
    .dropdown-menu {
      padding: 0;
      width: auto;
      max-height: none;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .datepicker-container_range,
  &.datepicker-container_range {
    display: flex;
    gap: calc(2 * var(--unit));
    .input-wrapper {
      flex: 1;
    }
  }

  @media (max-width: $mobile) {
    .calendar,
    &.calendar {
      width: calc(37 * var(--unit));
      max-height: calc(39 * var(--unit));
      padding: calc(2 * var(--unit)) var(--unit);
    }

    .calendar-navigation {
      margin-bottom: calc(2 * var(--unit));
    }

    button.calendar-navigation-btn_prev,
    button.calendar-navigation-btn_next {
      &:has(.material-icons:not(.left, .right)) {
        width: calc(3 * var(--unit));
        height: calc(3 * var(--unit));
      }
      .material-icons {
        font-size: calc(2 * var(--unit));
      }
    }

    button.calendar-navigation-label,
    .calendar-navigation-label {
      padding: 0;
      height: auto;
      max-height: none;
      .material-icons {
        font-size: calc(2 * var(--unit));
      }
    }

    button.calendar-day,
    button.calendar-day_today,
    button.calendar-day_selected,
    button.calendar-day_today_selected {
      width: calc(5 * var(--unit));
      height: calc(5 * var(--unit));
    }

    button.calendar-year,
    button.calendar-year_selected {
      width: calc(8.75 * var(--unit));
    }
  }
}
