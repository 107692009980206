%social-sharing-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
  background-color: var(--neutral0);
  mask-repeat: no-repeat;
  mask-position: center;
}
.tag-ds {
  .social-sharing,
  &.social-sharing {
    display: flex;
    align-items: center;
    gap: calc(3 * var(--unit));
    .social-sharing-icon {
      background: var(--neutral800);
      border-radius: 50%;
      position: relative;
      width: calc(3 * var(--unit));
      height: calc(3 * var(--unit));
      &.linkedin::after {
        @extend %social-sharing-icon;
        background-color: var(--neutral0);
        mask-image: url('../icons/linkedin.svg');
      }
      &.whatsapp::after {
        @extend %social-sharing-icon;
        background-color: var(--neutral0);
        mask-image: url('../icons/whatsapp.svg');
      }
      &.twitter::after {
        @extend %social-sharing-icon;
        background-color: var(--neutral0);
        mask-image: url('../icons/twitter.svg');
      }
      &.facebook::after {
        @extend %social-sharing-icon;
        background-color: var(--neutral0);
        mask-image: url('../icons/facebook.svg');
      }
    }

    .link_small {
      position: relative;
      margin-left: var(--unit);
      > span.right {
        font-size: calc(2 * var(--unit));
      }
      &::before {
        content: '';
        height: 100%;
        width: 1px;
        background: var(--neutral200);
        position: absolute;
        left: calc(-2 * var(--unit));
        top: 0;
      }
    }
  }

  .bg04,
  &.bg04 {
    .social-sharing {
      .social-sharing-icon {
        background: var(--neutral0);
        &.linkedin::after {
          background-color: var(--neutral800);
        }
        &.whatsapp::after {
          background-color: var(--neutral800);
        }
        &.twitter::after {
          background-color: var(--neutral800);
        }
        &.facebook::after {
          background-color: var(--neutral800);
        }
      }
      .link_small::before {
        background: var(--primary400);
      }
    }
  }
}
.bg04 {
  .tag-ds,
  &.tag-ds {
    .social-sharing {
      .social-sharing-icon {
        background: var(--neutral0);
        &.linkedin::after {
          background-color: var(--neutral800);
        }
        &.whatsapp::after {
          background-color: var(--neutral800);
        }
        &.twitter::after {
          background-color: var(--neutral800);
        }
        &.facebook::after {
          background-color: var(--neutral800);
        }
      }
      .link_small::before {
        background: var(--primary400);
      }
    }
  }
}
