.m0 {
  margin: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}

.m1 {
  margin: var(--unit) !important;
}
.mt1 {
  margin-top: var(--unit) !important;
}
.mr1 {
  margin-right: var(--unit) !important;
}
.mb1 {
  margin-bottom: var(--unit) !important;
}
.ml1 {
  margin-left: var(--unit) !important;
}

.m2 {
  margin: calc(2 * var(--unit)) !important;
}
.mt2 {
  margin-top: calc(2 * var(--unit)) !important;
}
.mr2 {
  margin-right: calc(2 * var(--unit)) !important;
}
.mb2 {
  margin-bottom: calc(2 * var(--unit)) !important;
}
.ml2 {
  margin-left: calc(2 * var(--unit)) !important;
}

.m3 {
  margin: calc(3 * var(--unit)) !important;
}
.mt3 {
  margin-top: calc(3 * var(--unit)) !important;
}
.mr3 {
  margin-right: calc(3 * var(--unit)) !important;
}
.mb3 {
  margin-bottom: calc(3 * var(--unit)) !important;
}
.ml3 {
  margin-left: calc(3 * var(--unit)) !important;
}

.m4 {
  margin: calc(4 * var(--unit)) !important;
}
.mt4 {
  margin-top: calc(4 * var(--unit)) !important;
}
.mr4 {
  margin-right: calc(4 * var(--unit)) !important;
}
.mb4 {
  margin-bottom: calc(4 * var(--unit)) !important;
}
.ml4 {
  margin-left: calc(4 * var(--unit)) !important;
}

.m5 {
  margin: calc(5 * var(--unit)) !important;
}
.mt5 {
  margin-top: calc(5 * var(--unit)) !important;
}
.mr5 {
  margin-right: calc(5 * var(--unit)) !important;
}
.mb5 {
  margin-bottom: calc(5 * var(--unit)) !important;
}
.ml5 {
  margin-left: calc(5 * var(--unit)) !important;
}

.m6 {
  margin: calc(6 * var(--unit)) !important;
}
.mt6 {
  margin-top: calc(6 * var(--unit)) !important;
}
.mr6 {
  margin-right: calc(6 * var(--unit)) !important;
}
.mb6 {
  margin-bottom: calc(6 * var(--unit)) !important;
}
.ml6 {
  margin-left: calc(6 * var(--unit)) !important;
}

.m7 {
  margin: calc(7 * var(--unit)) !important;
}
.mt7 {
  margin-top: calc(7 * var(--unit)) !important;
}
.mr7 {
  margin-right: calc(7 * var(--unit)) !important;
}
.mb7 {
  margin-bottom: calc(7 * var(--unit)) !important;
}
.ml7 {
  margin-left: calc(7 * var(--unit)) !important;
}

.m8 {
  margin: calc(8 * var(--unit)) !important;
}
.mt8 {
  margin-top: calc(8 * var(--unit)) !important;
}
.mr8 {
  margin-right: calc(8 * var(--unit)) !important;
}
.mb8 {
  margin-bottom: calc(8 * var(--unit)) !important;
}
.ml8 {
  margin-left: calc(8 * var(--unit)) !important;
}

.m9 {
  margin: calc(9 * var(--unit)) !important;
}
.mt9 {
  margin-top: calc(9 * var(--unit)) !important;
}
.mr9 {
  margin-right: calc(9 * var(--unit)) !important;
}
.mb9 {
  margin-bottom: calc(9 * var(--unit)) !important;
}
.ml9 {
  margin-left: calc(9 * var(--unit)) !important;
}

.m10 {
  margin: calc(10 * var(--unit)) !important;
}
.mt10 {
  margin-top: calc(10 * var(--unit)) !important;
}
.mr10 {
  margin-right: calc(10 * var(--unit)) !important;
}
.mb10 {
  margin-bottom: calc(10 * var(--unit)) !important;
}
.ml10 {
  margin-left: calc(10 * var(--unit)) !important;
}

.m20 {
  margin: calc(20 * var(--unit)) !important;
}
.mt20 {
  margin-top: calc(20 * var(--unit)) !important;
}
.mr20 {
  margin-right: calc(20 * var(--unit)) !important;
}
.mb20 {
  margin-bottom: calc(20 * var(--unit)) !important;
}
.ml20 {
  margin-left: calc(20 * var(--unit)) !important;
}

.m30 {
  margin: calc(30 * var(--unit)) !important;
}
.mt30 {
  margin-top: calc(30 * var(--unit)) !important;
}
.mr30 {
  margin-right: calc(30 * var(--unit)) !important;
}
.mb30 {
  margin-bottom: calc(30 * var(--unit)) !important;
}
.ml30 {
  margin-left: calc(30 * var(--unit)) !important;
}

.m40 {
  margin: calc(40 * var(--unit)) !important;
}
.mt40 {
  margin-top: calc(40 * var(--unit)) !important;
}
.mr40 {
  margin-right: calc(40 * var(--unit)) !important;
}
.mb40 {
  margin-bottom: calc(40 * var(--unit)) !important;
}
.ml40 {
  margin-left: calc(40 * var(--unit)) !important;
}

.m50 {
  margin: calc(50 * var(--unit)) !important;
}
.mt50 {
  margin-top: calc(50 * var(--unit)) !important;
}
.mr50 {
  margin-right: calc(50 * var(--unit)) !important;
}
.mb50 {
  margin-bottom: calc(50 * var(--unit)) !important;
}
.ml50 {
  margin-left: calc(50 * var(--unit)) !important;
}

//Paddings
.p0 {
  padding: calc(0) !important;
}
.pt0 {
  padding-top: calc(0) !important;
}
.pr0 {
  padding-right: calc(0) !important;
}
.pb0 {
  padding-bottom: calc(0) !important;
}
.pl0 {
  padding-left: calc(0) !important;
}

.p1 {
  padding: calc(var(--unit)) !important;
}
.pt1 {
  padding-top: calc(var(--unit)) !important;
}
.pr1 {
  padding-right: calc(var(--unit)) !important;
}
.pb1 {
  padding-bottom: calc(var(--unit)) !important;
}
.pl1 {
  padding-left: calc(var(--unit)) !important;
}

.p2 {
  padding: calc(2 * var(--unit)) !important;
}
.pt2 {
  padding-top: calc(2 * var(--unit)) !important;
}
.pr2 {
  padding-right: calc(2 * var(--unit)) !important;
}
.pb2 {
  padding-bottom: calc(2 * var(--unit)) !important;
}
.pl2 {
  padding-left: calc(2 * var(--unit)) !important;
}

.p3 {
  padding: calc(3 * var(--unit)) !important;
}
.pt3 {
  padding-top: calc(3 * var(--unit)) !important;
}
.pr3 {
  padding-right: calc(3 * var(--unit)) !important;
}
.pb3 {
  padding-bottom: calc(3 * var(--unit)) !important;
}
.pl3 {
  padding-left: calc(3 * var(--unit)) !important;
}

.p4 {
  padding: calc(4 * var(--unit)) !important;
}
.pt4 {
  padding-top: calc(4 * var(--unit)) !important;
}
.pr4 {
  padding-right: calc(4 * var(--unit)) !important;
}
.pb4 {
  padding-bottom: calc(4 * var(--unit)) !important;
}
.pl4 {
  padding-left: calc(4 * var(--unit)) !important;
}

.p5 {
  padding: calc(5 * var(--unit)) !important;
}
.pt5 {
  padding-top: calc(5 * var(--unit)) !important;
}
.pr5 {
  padding-right: calc(5 * var(--unit)) !important;
}
.pb5 {
  padding-bottom: calc(5 * var(--unit)) !important;
}
.pl5 {
  padding-left: calc(5 * var(--unit)) !important;
}

.p6 {
  padding: calc(6 * var(--unit)) !important;
}
.pt6 {
  padding-top: calc(6 * var(--unit)) !important;
}
.pr6 {
  padding-right: calc(6 * var(--unit)) !important;
}
.pb6 {
  padding-bottom: calc(6 * var(--unit)) !important;
}
.pl6 {
  padding-left: calc(6 * var(--unit)) !important;
}

.p7 {
  padding: calc(7 * var(--unit)) !important;
}
.pt7 {
  padding-top: calc(7 * var(--unit)) !important;
}
.pr7 {
  padding-right: calc(7 * var(--unit)) !important;
}
.pb7 {
  padding-bottom: calc(7 * var(--unit)) !important;
}
.pl7 {
  padding-left: calc(7 * var(--unit)) !important;
}

.p8 {
  padding: calc(8 * var(--unit)) !important;
}
.pt8 {
  padding-top: calc(8 * var(--unit)) !important;
}
.pr8 {
  padding-right: calc(8 * var(--unit)) !important;
}
.pb8 {
  padding-bottom: calc(8 * var(--unit)) !important;
}
.pl8 {
  padding-left: calc(8 * var(--unit)) !important;
}

.p9 {
  padding: calc(9 * var(--unit)) !important;
}
.pt9 {
  padding-top: calc(9 * var(--unit)) !important;
}
.pr9 {
  padding-right: calc(9 * var(--unit)) !important;
}
.pb9 {
  padding-bottom: calc(9 * var(--unit)) !important;
}
.pl9 {
  padding-left: calc(9 * var(--unit)) !important;
}

.p10 {
  padding: calc(10 * var(--unit)) !important;
}
.pt10 {
  padding-top: calc(10 * var(--unit)) !important;
}
.pr10 {
  padding-right: calc(10 * var(--unit)) !important;
}
.pb10 {
  padding-bottom: calc(10 * var(--unit)) !important;
}
.pl10 {
  padding-left: calc(10 * var(--unit)) !important;
}

.p20 {
  padding: calc(20 * var(--unit)) !important;
}
.pt20 {
  padding-top: calc(20 * var(--unit)) !important;
}
.pr20 {
  padding-right: calc(20 * var(--unit)) !important;
}
.pb20 {
  padding-bottom: calc(20 * var(--unit)) !important;
}
.pl20 {
  padding-left: calc(20 * var(--unit)) !important;
}

.p30 {
  padding: calc(30 * var(--unit)) !important;
}
.pt30 {
  padding-top: calc(30 * var(--unit)) !important;
}
.pr30 {
  padding-right: calc(30 * var(--unit)) !important;
}
.pb30 {
  padding-bottom: calc(30 * var(--unit)) !important;
}
.pl30 {
  padding-left: calc(30 * var(--unit)) !important;
}

.p40 {
  padding: calc(40 * var(--unit)) !important;
}
.pt40 {
  padding-top: calc(40 * var(--unit)) !important;
}
.pr40 {
  padding-right: calc(40 * var(--unit)) !important;
}
.pb40 {
  padding-bottom: calc(40 * var(--unit)) !important;
}
.pl40 {
  padding-left: calc(40 * var(--unit)) !important;
}

.p50 {
  padding: calc(50 * var(--unit)) !important;
}
.pt50 {
  padding-top: calc(50 * var(--unit)) !important;
}
.pr50 {
  padding-right: calc(50 * var(--unit)) !important;
}
.pb50 {
  padding-bottom: calc(50 * var(--unit)) !important;
}
.pl50 {
  padding-left: calc(50 * var(--unit)) !important;
}

@media (max-width: $tablet) {
  //Margins
  .m0_tablet {
    margin: 0 !important;
  }
  .mt0_tablet {
    margin-top: 0 !important;
  }
  .mr0_tablet {
    margin-right: 0 !important;
  }
  .mb0_tablet {
    margin-bottom: 0 !important;
  }
  .ml0_tablet {
    margin-left: 0 !important;
  }

  .m1_tablet {
    margin: var(--unit) !important;
  }
  .mt1_tablet {
    margin-top: var(--unit) !important;
  }
  .mr1_tablet {
    margin-right: var(--unit) !important;
  }
  .mb1_tablet {
    margin-bottom: var(--unit) !important;
  }
  .ml1_tablet {
    margin-left: var(--unit) !important;
  }

  .m2_tablet {
    margin: calc(2 * var(--unit)) !important;
  }
  .mt2_tablet {
    margin-top: calc(2 * var(--unit)) !important;
  }
  .mr2_tablet {
    margin-right: calc(2 * var(--unit)) !important;
  }
  .mb2_tablet {
    margin-bottom: calc(2 * var(--unit)) !important;
  }
  .ml2_tablet,
  l2_tablet {
    margin-left: calc(2 * var(--unit)) !important;
  }

  .m3_tablet {
    margin: calc(3 * var(--unit)) !important;
  }
  .mt3_tablet {
    margin-top: calc(3 * var(--unit)) !important;
  }
  .mr3_tablet {
    margin-right: calc(3 * var(--unit)) !important;
  }
  .mb3_tablet {
    margin-bottom: calc(3 * var(--unit)) !important;
  }
  .ml3_tablet {
    margin-left: calc(3 * var(--unit)) !important;
  }

  .m4_tablet {
    margin: calc(4 * var(--unit)) !important;
  }
  .mt4_tablet {
    margin-top: calc(4 * var(--unit)) !important;
  }
  .mr4_tablet {
    margin-right: calc(4 * var(--unit)) !important;
  }
  .mb4_tablet {
    margin-bottom: calc(4 * var(--unit)) !important;
  }
  .ml4_tablet {
    margin-left: calc(4 * var(--unit)) !important;
  }

  .m5_tablet {
    margin: calc(5 * var(--unit)) !important;
  }
  .mt5_tablet {
    margin-top: calc(5 * var(--unit)) !important;
  }
  .mr5_tablet {
    margin-right: calc(5 * var(--unit)) !important;
  }
  .mb5_tablet {
    margin-bottom: calc(5 * var(--unit)) !important;
  }
  .ml5_tablet {
    margin-left: calc(5 * var(--unit)) !important;
  }

  .m6_tablet {
    margin: calc(6 * var(--unit)) !important;
  }
  .mt6_tablet {
    margin-top: calc(6 * var(--unit)) !important;
  }
  .mr6_tablet {
    margin-right: calc(6 * var(--unit)) !important;
  }
  .mb6_tablet {
    margin-bottom: calc(6 * var(--unit)) !important;
  }
  .ml6_tablet {
    margin-left: calc(6 * var(--unit)) !important;
  }

  .m7_tablet {
    margin: calc(7 * var(--unit)) !important;
  }
  .mt7_tablet {
    margin-top: calc(7 * var(--unit)) !important;
  }
  .mr7_tablet {
    margin-right: calc(7 * var(--unit)) !important;
  }
  .mb7_tablet {
    margin-bottom: calc(7 * var(--unit)) !important;
  }
  .ml7_tablet {
    margin-left: calc(7 * var(--unit)) !important;
  }

  .m8_tablet {
    margin: calc(8 * var(--unit)) !important;
  }
  .mt8_tablet {
    margin-top: calc(8 * var(--unit)) !important;
  }
  .mr8_tablet {
    margin-right: calc(8 * var(--unit)) !important;
  }
  .mb8_tablet {
    margin-bottom: calc(8 * var(--unit)) !important;
  }
  .ml8_tablet {
    margin-left: calc(8 * var(--unit)) !important;
  }

  .m9_tablet {
    margin: calc(9 * var(--unit)) !important;
  }
  .mt9_tablet {
    margin-top: calc(9 * var(--unit)) !important;
  }
  .mr9_tablet {
    margin-right: calc(9 * var(--unit)) !important;
  }
  .mb9_tablet {
    margin-bottom: calc(9 * var(--unit)) !important;
  }
  .ml9_tablet {
    margin-left: calc(9 * var(--unit)) !important;
  }

  .m10_tablet {
    margin: calc(10 * var(--unit)) !important;
  }
  .mt10_tablet {
    margin-top: calc(10 * var(--unit)) !important;
  }
  .mr10_tablet {
    margin-right: calc(10 * var(--unit)) !important;
  }
  .mb10_tablet {
    margin-bottom: calc(10 * var(--unit)) !important;
  }
  .ml10_tablet {
    margin-left: calc(10 * var(--unit)) !important;
  }

  .m20_tablet {
    margin: calc(20 * var(--unit)) !important;
  }
  .mt20_tablet {
    margin-top: calc(20 * var(--unit)) !important;
  }
  .mr20_tablet {
    margin-right: calc(20 * var(--unit)) !important;
  }
  .mb20_tablet {
    margin-bottom: calc(20 * var(--unit)) !important;
  }
  .ml20_tablet {
    margin-left: calc(20 * var(--unit)) !important;
  }

  .m30_tablet {
    margin: calc(30 * var(--unit)) !important;
  }
  .mt30_tablet {
    margin-top: calc(30 * var(--unit)) !important;
  }
  .mr30_tablet {
    margin-right: calc(30 * var(--unit)) !important;
  }
  .mb30_tablet {
    margin-bottom: calc(30 * var(--unit)) !important;
  }
  .ml30_tablet {
    margin-left: calc(30 * var(--unit)) !important;
  }

  .m40_tablet {
    margin: calc(40 * var(--unit)) !important;
  }
  .mt40_tablet {
    margin-top: calc(40 * var(--unit)) !important;
  }
  .mr40_tablet {
    margin-right: calc(40 * var(--unit)) !important;
  }
  .mb40_tablet,
  b40_tablet {
    margin-bottom: calc(40 * var(--unit)) !important;
  }
  .ml40_tablet {
    margin-left: calc(40 * var(--unit)) !important;
  }

  .m50_tablet {
    margin: calc(50 * var(--unit)) !important;
  }
  .mt50_tablet {
    margin-top: calc(50 * var(--unit)) !important;
  }
  .mr50_tablet {
    margin-right: calc(50 * var(--unit)) !important;
  }
  .mb50_tablet {
    margin-bottom: calc(50 * var(--unit)) !important;
  }
  .ml50_tablet {
    margin-left: calc(50 * var(--unit)) !important;
  }

  //Paddings
  .p0_tablet {
    padding: calc(0) !important;
  }
  .pt0_tablet {
    padding-top: calc(0) !important;
  }
  .pr0_tablet {
    padding-right: calc(0) !important;
  }
  .pb0_tablet {
    padding-bottom: calc(0) !important;
  }
  .pl0_tablet {
    padding-left: calc(0) !important;
  }

  .p1_tablet {
    padding: calc(var(--unit)) !important;
  }
  .pt1_tablet {
    padding-top: calc(var(--unit)) !important;
  }
  .pr1_tablet {
    padding-right: calc(var(--unit)) !important;
  }
  .pb1_tablet {
    padding-bottom: calc(var(--unit)) !important;
  }
  .pl1_tablet {
    padding-left: calc(var(--unit)) !important;
  }

  .p2_tablet {
    padding: calc(2 * var(--unit)) !important;
  }
  .pt2_tablet {
    padding-top: calc(2 * var(--unit)) !important;
  }
  .pr2_tablet {
    padding-right: calc(2 * var(--unit)) !important;
  }
  .pb2_tablet {
    padding-bottom: calc(2 * var(--unit)) !important;
  }
  .pl2_tablet {
    padding-left: calc(2 * var(--unit)) !important;
  }

  .p3_tablet {
    padding: calc(3 * var(--unit)) !important;
  }
  .pt3_tablet {
    padding-top: calc(3 * var(--unit)) !important;
  }
  .pr3_tablet {
    padding-right: calc(3 * var(--unit)) !important;
  }
  .pb3_tablet {
    padding-bottom: calc(3 * var(--unit)) !important;
  }
  .pl3_tablet {
    padding-left: calc(3 * var(--unit)) !important;
  }

  .p4_tablet {
    padding: calc(4 * var(--unit)) !important;
  }
  .pt4_tablet {
    padding-top: calc(4 * var(--unit)) !important;
  }
  .pr4_tablet {
    padding-right: calc(4 * var(--unit)) !important;
  }
  .pb4_tablet {
    padding-bottom: calc(4 * var(--unit)) !important;
  }
  .pl4_tablet {
    padding-left: calc(4 * var(--unit)) !important;
  }

  .p5_tablet {
    padding: calc(5 * var(--unit)) !important;
  }
  .pt5_tablet {
    padding-top: calc(5 * var(--unit)) !important;
  }
  .pr5_tablet {
    padding-right: calc(5 * var(--unit)) !important;
  }
  .pb5_tablet {
    padding-bottom: calc(5 * var(--unit)) !important;
  }
  .pl5_tablet {
    padding-left: calc(5 * var(--unit)) !important;
  }

  .p6_tablet {
    padding: calc(6 * var(--unit)) !important;
  }
  .pt6_tablet {
    padding-top: calc(6 * var(--unit)) !important;
  }
  .pr6_tablet {
    padding-right: calc(6 * var(--unit)) !important;
  }
  .pb6_tablet b6_tablet {
    padding-bottom: calc(6 * var(--unit)) !important;
  }
  .pl6_tablet {
    padding-left: calc(6 * var(--unit)) !important;
  }

  .p7_tablet {
    padding: calc(7 * var(--unit)) !important;
  }
  .pt7_tablet {
    padding-top: calc(7 * var(--unit)) !important;
  }
  .pr7_tablet {
    padding-right: calc(7 * var(--unit)) !important;
  }
  .pb7_tablet {
    padding-bottom: calc(7 * var(--unit)) !important;
  }
  .pl7_tablet {
    padding-left: calc(7 * var(--unit)) !important;
  }

  .p8_tablet {
    padding: calc(8 * var(--unit)) !important;
  }
  .pt8_tablet {
    padding-top: calc(8 * var(--unit)) !important;
  }
  .pr8_tablet {
    padding-right: calc(8 * var(--unit)) !important;
  }
  .pb8_tablet {
    padding-bottom: calc(8 * var(--unit)) !important;
  }
  .pl8_tablet {
    padding-left: calc(8 * var(--unit)) !important;
  }

  .p9_tablet {
    padding: calc(9 * var(--unit)) !important;
  }
  .pt9_tablet {
    padding-top: calc(9 * var(--unit)) !important;
  }
  .pr9_tablet {
    padding-right: calc(9 * var(--unit)) !important;
  }
  .pb9_tablet {
    padding-bottom: calc(9 * var(--unit)) !important;
  }
  .pl9_tablet {
    padding-left: calc(9 * var(--unit)) !important;
  }

  .p10_tablet {
    padding: calc(10 * var(--unit)) !important;
  }
  .pt10_tablet {
    padding-top: calc(10 * var(--unit)) !important;
  }
  .pr10_tablet {
    padding-right: calc(10 * var(--unit)) !important;
  }
  .pb10_tablet {
    padding-bottom: calc(10 * var(--unit)) !important;
  }
  .pl10_tablet {
    padding-left: calc(10 * var(--unit)) !important;
  }

  .p20_tablet {
    padding: calc(20 * var(--unit)) !important;
  }
  .pt20_tablet {
    padding-top: calc(20 * var(--unit)) !important;
  }
  .pr20_tablet {
    padding-right: calc(20 * var(--unit)) !important;
  }
  .pb20_tablet {
    padding-bottom: calc(20 * var(--unit)) !important;
  }
  .pl20_tablet {
    padding-left: calc(20 * var(--unit)) !important;
  }

  .p30_tablet {
    padding: calc(30 * var(--unit)) !important;
  }
  .pt30_tablet {
    padding-top: calc(30 * var(--unit)) !important;
  }
  .pr30_tablet {
    padding-right: calc(30 * var(--unit)) !important;
  }
  .pb30_tablet {
    padding-bottom: calc(30 * var(--unit)) !important;
  }
  .pl30_tablet {
    padding-left: calc(30 * var(--unit)) !important;
  }

  .p40_tablet {
    padding: calc(40 * var(--unit)) !important;
  }
  .pt40_tablet {
    padding-top: calc(40 * var(--unit)) !important;
  }
  .pr40_tablet {
    padding-right: calc(40 * var(--unit)) !important;
  }
  .pb40_tablet {
    padding-bottom: calc(40 * var(--unit)) !important;
  }
  .pl40_tablet {
    padding-left: calc(40 * var(--unit)) !important;
  }

  .p50_tablet {
    padding: calc(50 * var(--unit)) !important;
  }
  .pt50_tablet {
    padding-top: calc(50 * var(--unit)) !important;
  }
  .pr50_tablet {
    padding-right: calc(50 * var(--unit)) !important;
  }
  .pb50_tablet {
    padding-bottom: calc(50 * var(--unit)) !important;
  }
  .pl50_tablet {
    padding-left: calc(50 * var(--unit)) !important;
  }
}
@media (max-width: $mobile) {
  //Margins
  .m0_mobile {
    margin: 0 !important;
  }
  .mt0_mobile {
    margin-top: 0 !important;
  }
  .mr0_mobile {
    margin-right: 0 !important;
  }
  .mb0_mobile {
    margin-bottom: 0 !important;
  }
  .ml0_mobile {
    margin-left: 0 !important;
  }

  .m1_mobile {
    margin: var(--unit) !important;
  }
  .mt1_mobile {
    margin-top: var(--unit) !important;
  }
  .mr1_mobile {
    margin-right: var(--unit) !important;
  }
  .mb1_mobile {
    margin-bottom: var(--unit) !important;
  }
  .ml1_mobile {
    margin-left: var(--unit) !important;
  }

  .m2_mobile {
    margin: calc(2 * var(--unit)) !important;
  }
  .mt2_mobile {
    margin-top: calc(2 * var(--unit)) !important;
  }
  .mr2_mobile {
    margin-right: calc(2 * var(--unit)) !important;
  }
  .mb2_mobile {
    margin-bottom: calc(2 * var(--unit)) !important;
  }
  .ml2_mobile {
    margin-left: calc(2 * var(--unit)) !important;
  }

  .m3_mobile {
    margin: calc(3 * var(--unit)) !important;
  }
  .mt3_mobile {
    margin-top: calc(3 * var(--unit)) !important;
  }
  .mr3_mobile {
    margin-right: calc(3 * var(--unit)) !important;
  }
  .mb3_mobile {
    margin-bottom: calc(3 * var(--unit)) !important;
  }
  .ml3_mobile {
    margin-left: calc(3 * var(--unit)) !important;
  }

  .m4_mobile {
    margin: calc(4 * var(--unit)) !important;
  }
  .mt4_mobile {
    margin-top: calc(4 * var(--unit)) !important;
  }
  .mr4_mobile {
    margin-right: calc(4 * var(--unit)) !important;
  }
  .mb4_mobile {
    margin-bottom: calc(4 * var(--unit)) !important;
  }
  .ml4_mobile {
    margin-left: calc(4 * var(--unit)) !important;
  }

  .m5_mobile {
    margin: calc(5 * var(--unit)) !important;
  }
  .mt5_mobile {
    margin-top: calc(5 * var(--unit)) !important;
  }
  .mr5_mobile {
    margin-right: calc(5 * var(--unit)) !important;
  }
  .mb5_mobile {
    margin-bottom: calc(5 * var(--unit)) !important;
  }
  .ml5_mobile {
    margin-left: calc(5 * var(--unit)) !important;
  }

  .m6_mobile {
    margin: calc(6 * var(--unit)) !important;
  }
  .mt6_mobile {
    margin-top: calc(6 * var(--unit)) !important;
  }
  .mr6_mobile {
    margin-right: calc(6 * var(--unit)) !important;
  }
  .mb6_mobile {
    margin-bottom: calc(6 * var(--unit)) !important;
  }
  .ml6_mobile {
    margin-left: calc(6 * var(--unit)) !important;
  }

  .m7_mobile {
    margin: calc(7 * var(--unit)) !important;
  }
  .mt7_mobile {
    margin-top: calc(7 * var(--unit)) !important;
  }
  .mr7_mobile {
    margin-right: calc(7 * var(--unit)) !important;
  }
  .mb7_mobile {
    margin-bottom: calc(7 * var(--unit)) !important;
  }
  .ml7_mobile {
    margin-left: calc(7 * var(--unit)) !important;
  }

  .m8_mobile {
    margin: calc(8 * var(--unit)) !important;
  }
  .mt8_mobile {
    margin-top: calc(8 * var(--unit)) !important;
  }
  .mr8_mobile {
    margin-right: calc(8 * var(--unit)) !important;
  }
  .mb8_mobile {
    margin-bottom: calc(8 * var(--unit)) !important;
  }
  .ml8_mobile {
    margin-left: calc(8 * var(--unit)) !important;
  }

  .m9_mobile {
    margin: calc(9 * var(--unit)) !important;
  }
  .mt9_mobile {
    margin-top: calc(9 * var(--unit)) !important;
  }
  .mr9_mobile {
    margin-right: calc(9 * var(--unit)) !important;
  }
  .mb9_mobile {
    margin-bottom: calc(9 * var(--unit)) !important;
  }
  .ml9_mobile {
    margin-left: calc(9 * var(--unit)) !important;
  }

  .m10_mobile {
    margin: calc(10 * var(--unit)) !important;
  }
  .mt10_mobile {
    margin-top: calc(10 * var(--unit)) !important;
  }
  .mr10_mobile {
    margin-right: calc(10 * var(--unit)) !important;
  }
  .mb10_mobile {
    margin-bottom: calc(10 * var(--unit)) !important;
  }
  .ml10_mobile {
    margin-left: calc(10 * var(--unit)) !important;
  }

  .m20_mobile {
    margin: calc(20 * var(--unit)) !important;
  }
  .mt20_mobile {
    margin-top: calc(20 * var(--unit)) !important;
  }
  .mr20_mobile {
    margin-right: calc(20 * var(--unit)) !important;
  }
  .mb20_mobile {
    margin-bottom: calc(20 * var(--unit)) !important;
  }
  .ml20_mobile {
    margin-left: calc(20 * var(--unit)) !important;
  }

  .m30_mobile {
    margin: calc(30 * var(--unit)) !important;
  }
  .mt30_mobile {
    margin-top: calc(30 * var(--unit)) !important;
  }
  .mr30_mobile {
    margin-right: calc(30 * var(--unit)) !important;
  }
  .mb30_mobile {
    margin-bottom: calc(30 * var(--unit)) !important;
  }
  .ml30_mobile {
    margin-left: calc(30 * var(--unit)) !important;
  }

  .m40_mobile {
    margin: calc(40 * var(--unit)) !important;
  }
  .mt40_mobile {
    margin-top: calc(40 * var(--unit)) !important;
  }
  .mr40_mobile {
    margin-right: calc(40 * var(--unit)) !important;
  }
  .mb40_mobile {
    margin-bottom: calc(40 * var(--unit)) !important;
  }
  .ml40_mobile {
    margin-left: calc(40 * var(--unit)) !important;
  }

  .m50_mobile {
    margin: calc(50 * var(--unit)) !important;
  }
  .mt50_mobile {
    margin-top: calc(50 * var(--unit)) !important;
  }
  .mr50_mobile {
    margin-right: calc(50 * var(--unit)) !important;
  }
  .mb50_mobile {
    margin-bottom: calc(50 * var(--unit)) !important;
  }
  .ml50_mobile {
    margin-left: calc(50 * var(--unit)) !important;
  }

  //Paddings
  .p0_mobile {
    padding: calc(0) !important;
  }
  .pt0_mobile {
    padding-top: calc(0) !important;
  }
  .pr0_mobile {
    padding-right: calc(0) !important;
  }
  .pb0_mobile {
    padding-bottom: calc(0) !important;
  }
  .pl0_mobile {
    padding-left: calc(0) !important;
  }

  .p1_mobile {
    padding: calc(var(--unit)) !important;
  }
  .pt1_mobile {
    padding-top: calc(var(--unit)) !important;
  }
  .pr1_mobile {
    padding-right: calc(var(--unit)) !important;
  }
  .pb1_mobile {
    padding-bottom: calc(var(--unit)) !important;
  }
  .pl1_mobile {
    padding-left: calc(var(--unit)) !important;
  }

  .p2_mobile {
    padding: calc(2 * var(--unit)) !important;
  }
  .pt2_mobile {
    padding-top: calc(2 * var(--unit)) !important;
  }
  .pr2_mobile {
    padding-right: calc(2 * var(--unit)) !important;
  }
  .pb2_mobile {
    padding-bottom: calc(2 * var(--unit)) !important;
  }
  .pl2_mobile {
    padding-left: calc(2 * var(--unit)) !important;
  }

  .p3_mobile {
    padding: calc(3 * var(--unit)) !important;
  }
  .pt3_mobile {
    padding-top: calc(3 * var(--unit)) !important;
  }
  .pr3_mobile {
    padding-right: calc(3 * var(--unit)) !important;
  }
  .pb3_mobile {
    padding-bottom: calc(3 * var(--unit)) !important;
  }
  .pl3_mobile {
    padding-left: calc(3 * var(--unit)) !important;
  }

  .p4_mobile {
    padding: calc(4 * var(--unit)) !important;
  }
  .pt4_mobile {
    padding-top: calc(4 * var(--unit)) !important;
  }
  .pr4_mobile {
    padding-right: calc(4 * var(--unit)) !important;
  }
  .pb4_mobile {
    padding-bottom: calc(4 * var(--unit)) !important;
  }
  .pl4_mobile {
    padding-left: calc(4 * var(--unit)) !important;
  }

  .p5_mobile {
    padding: calc(5 * var(--unit)) !important;
  }
  .pt5_mobile {
    padding-top: calc(5 * var(--unit)) !important;
  }
  .pr5_mobile {
    padding-right: calc(5 * var(--unit)) !important;
  }
  .pb5_mobile {
    padding-bottom: calc(5 * var(--unit)) !important;
  }
  .pl5_mobile {
    padding-left: calc(5 * var(--unit)) !important;
  }

  .p6_mobile {
    padding: calc(6 * var(--unit)) !important;
  }
  .pt6_mobile {
    padding-top: calc(6 * var(--unit)) !important;
  }
  .pr6_mobile {
    padding-right: calc(6 * var(--unit)) !important;
  }
  .pb6_mobile {
    padding-bottom: calc(6 * var(--unit)) !important;
  }
  .pl6_mobile {
    padding-left: calc(6 * var(--unit)) !important;
  }

  .p7_mobile {
    padding: calc(7 * var(--unit)) !important;
  }
  .pt7_mobile {
    padding-top: calc(7 * var(--unit)) !important;
  }
  .pr7_mobile {
    padding-right: calc(7 * var(--unit)) !important;
  }
  .pb7_mobile {
    padding-bottom: calc(7 * var(--unit)) !important;
  }
  .pl7_mobile {
    padding-left: calc(7 * var(--unit)) !important;
  }

  .p8_mobile {
    padding: calc(8 * var(--unit)) !important;
  }
  .pt8_mobile {
    padding-top: calc(8 * var(--unit)) !important;
  }
  .pr8_mobile {
    padding-right: calc(8 * var(--unit)) !important;
  }
  .pb8_mobile {
    padding-bottom: calc(8 * var(--unit)) !important;
  }
  .pl8_mobile {
    padding-left: calc(8 * var(--unit)) !important;
  }

  .p9_mobile {
    padding: calc(9 * var(--unit)) !important;
  }
  .pt9_mobile {
    padding-top: calc(9 * var(--unit)) !important;
  }
  .pr9_mobile {
    padding-right: calc(9 * var(--unit)) !important;
  }
  .pb9_mobile {
    padding-bottom: calc(9 * var(--unit)) !important;
  }
  .pl9_mobile {
    padding-left: calc(9 * var(--unit)) !important;
  }

  .p10_mobile {
    padding: calc(10 * var(--unit)) !important;
  }
  .pt10_mobile {
    padding-top: calc(10 * var(--unit)) !important;
  }
  .pr10_mobile {
    padding-right: calc(10 * var(--unit)) !important;
  }
  .pb10_mobile {
    padding-bottom: calc(10 * var(--unit)) !important;
  }
  .pl10_mobile {
    padding-left: calc(10 * var(--unit)) !important;
  }

  .p20_mobile {
    padding: calc(20 * var(--unit)) !important;
  }
  .pt20_mobile {
    padding-top: calc(20 * var(--unit)) !important;
  }
  .pr20_mobile {
    padding-right: calc(20 * var(--unit)) !important;
  }
  .pb20_mobile {
    padding-bottom: calc(20 * var(--unit)) !important;
  }
  .pl20_mobile {
    padding-left: calc(20 * var(--unit)) !important;
  }

  .p30_mobile {
    padding: calc(30 * var(--unit)) !important;
  }
  .pt30_mobile {
    padding-top: calc(30 * var(--unit)) !important;
  }
  .pr30_mobile {
    padding-right: calc(30 * var(--unit)) !important;
  }
  .pb30_mobile {
    padding-bottom: calc(30 * var(--unit)) !important;
  }
  .pl30_mobile {
    padding-left: calc(30 * var(--unit)) !important;
  }

  .p40_mobile {
    padding: calc(40 * var(--unit)) !important;
  }
  .pt40_mobile {
    padding-top: calc(40 * var(--unit)) !important;
  }
  .pr40_mobile {
    padding-right: calc(40 * var(--unit)) !important;
  }
  .pb40_mobile {
    padding-bottom: calc(40 * var(--unit)) !important;
  }
  .pl40_mobile {
    padding-left: calc(40 * var(--unit)) !important;
  }

  .p50_mobile {
    padding: calc(50 * var(--unit)) !important;
  }
  .pt50_mobile {
    padding-top: calc(50 * var(--unit)) !important;
  }
  .pr50_mobile {
    padding-right: calc(50 * var(--unit)) !important;
  }
  .pb50_mobile {
    padding-bottom: calc(50 * var(--unit)) !important;
  }
  .pl50_mobile {
    padding-left: calc(50 * var(--unit)) !important;
  }
}

// Grid
.g-container,
.g-container_12 {
  max-width: 100%;
  margin: 0 auto;
  clear: left;
  overflow: visible;
  padding: 0;
}
.clearfix {
  display: block;
  height: 1px;
  zoom: 1;
  width: 100%;
  clear: both;
  margin-bottom: -1px;
}

.g-item_1,
.g-item_2,
.g-item_3,
.g-item_4,
.g-item_5,
.g-item_6,
.g-item_7,
.g-item_8,
.g-item_9,
.g-item_10,
.g-item_11,
.g-item_12,
.g-item_13,
.g-item_14,
.g-item_15,
.g-item_16,
.g-item_tablet_1,
.g-item_tablet_2,
.g-item_tablet_3,
.g-item_tablet_4,
.g-item_tablet_5,
.g-item_tablet_6,
.g-item_tablet_7,
.g-item_tablet_8,
.g-item_mobile_1,
.g-item_mobile_2,
.g-item_mobile_3,
.g-item_mobile_4 {
  float: left;
}

.g-container {
  .g-item_1 {
    width: calc(100% / 16);
  }
  .g-item_2 {
    width: calc(100% / 8);
  }
  .g-item_3 {
    width: calc(100% / (16 / 3));
  }
  .g-item_4 {
    width: calc(100% / 4);
  }
  .g-item_5 {
    width: calc(100% / (16 / 5));
  }
  .g-item_6 {
    width: calc(100% / (16 / 6));
  }
  .g-item_7 {
    width: calc(100% / (16 / 7));
  }
  .g-item_8 {
    width: calc(100% / 2);
  }
  .g-item_9 {
    width: calc(100% / (16 / 9));
  }
  .g-item_10 {
    width: calc(100% / 1.6);
  }
  .g-item_11 {
    width: calc(100% / (16 / 11));
  }
  .g-item_12 {
    width: calc(100% / (16 / 12));
  }
  .g-item_13 {
    width: calc(100% / (16 / 13));
  }
  .g-item_14 {
    width: calc(100% / (16 / 14));
  }
  .g-item_15 {
    width: calc(100% / (16 / 15));
  }
  .g-item_16 {
    width: 100%;
  }
}

.g-container_12 {
  .g-item_1 {
    width: calc(100% / 12);
  }
  .g-item_2 {
    width: calc(100% / 6);
  }
  .g-item_3 {
    width: calc(100% / 4);
  }
  .g-item_4 {
    width: calc(100% / 3);
  }
  .g-item_5 {
    width: calc(100% / (12 / 5));
  }
  .g-item_6 {
    width: calc(100% / 2);
  }
  .g-item_7 {
    width: calc(100% / (12 / 7));
  }
  .g-item_8 {
    width: calc(100% / (12 / 8));
  }
  .g-item_9 {
    width: calc(100% / (12 / 9));
  }
  .g-item_10 {
    width: calc(100% / (12 / 10));
  }
  .g-item_11 {
    width: calc(100% / (12 / 11));
  }
  .g-item_12 {
    width: 100%;
  }
}

@media (max-width: $mediumLandscape) {
  .g-container,
  .g-container_12 {
    .g-item_1,
    .g-item_2,
    .g-item_3,
    .g-item_4,
    .g-item_5,
    .g-item_6,
    .g-item_7,
    .g-item_8,
    .g-item_9,
    .g-item_10,
    .g-item_11,
    .g-item_12,
    .g-item_13,
    .g-item_14,
    .g-item_15,
    .g-item_16 {
      width: 100%;
    }
  }

  .g-container .g-item_tablet_1 {
    width: calc(100% / 8);
  }
  .g-item_tablet_2 {
    width: calc(100% / 4);
  }
  .g-item_tablet_3 {
    width: calc(100% / (8 / 3));
  }
  .g-item_tablet_4 {
    width: calc(100% / 2);
  }
  .g-item_tablet_5 {
    width: calc(100% / (8 / 5));
  }
  .g-item_tablet_6 {
    width: calc(100% / (8 / 6));
  }
  .g-item_tablet_7 {
    width: calc(100% / (8 / 7));
  }
  .g-item_tablet_8 {
    width: 100%;
  }
}

.g-container_12 {
  .g-item_tablet_1 {
    width: calc(100% / 6);
  }
  .g-item_tablet_2 {
    width: calc(100% / 3);
  }
  .g-item_tablet_3 {
    width: calc(100% / 2);
  }
  .g-item_tablet_4 {
    width: calc(100% / (6 / 4));
  }
  .g-item_tablet_5 {
    width: calc(100% / (6 / 5));
  }
  .g-item_tablet_6 {
    width: 100%;
  }
}

@media (max-width: $mobile) {
  .g-container,
  .g-container_12 {
    .g-item_tablet_1,
    .g-item_tablet_2,
    .g-item_tablet_3,
    .g-item_tablet_4,
    .g-item_tablet_5,
    .g-item_tablet_6,
    .g-item_tablet_7,
    .g-item_tablet_8 {
      width: 100%;
    }
  }
  .g-container {
    .g-item_mobile_1 {
      width: calc(100% / 4);
    }
    .g-item_mobile_2 {
      width: calc(100% / 2);
    }
    .g-item_mobile_3 {
      width: calc(100% / (4 / 3));
    }
    .g-item_mobile_4 {
      width: calc(100% / 1);
    }
  }

  .g-container_12 {
    .g-item_mobile_1 {
      width: calc(100% / 3);
    }
    .g-item_mobile_2 {
      width: calc(100% / (3 / 2));
    }
    .g-item_mobile_3 {
      width: 100%;
    }
  }
}

