.tag-ds {
  .header,
  &.header {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: calc(10 * var(--unit));
    padding: 0 calc(4 * var(--unit));
    border-bottom: calc(var(--unit) / 8) solid var(--neutral200);

    .tab-list {
      box-shadow: inherit;
      height: 100%;
      margin: 0;
      padding: 0;
      gap: calc(6 * var(--unit));
      margin-right: calc(7 * var(--unit));
      .tab-list-item {
        height: calc(10 * var(--unit));
        position: relative;
        padding: 0;
        &.active {
          box-shadow: inherit;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(var(--unit) / 2);
            background: var(--accent500);
            border-top-left-radius: var(--unit);
            border-top-right-radius: var(--unit);
          }
        }
      }
    }
  }

  .header-logo {
    margin-right: calc(7 * var(--unit));
    height: auto;
    width: calc(10 * var(--unit));
    aspect-ratio: 16/9;
  }

  .header-item-overflow-menu,
  .header-item-overflow-menu_divider {
    width: auto;
    top: calc(100% + 2 * var(--unit));
    left: 0;
    text-align: left;
    padding: 0;

    .overflow-menu-header {
      margin: 0;
    }
    .overflow-menu-title {
      padding: var(--unit) calc(3 * var(--unit));
      margin: 0;
      color: var(--neutral800);
    }
    .dropdown-item {
      display: flex;
      padding: var(--unit) calc(7 * var(--unit)) var(--unit) calc(3 * var(--unit));
      &:not(a.dropdown-item) {
        color: var(--neutral800);
      }
    }
  }

  .header-item-overflow-menu_divider {
    & > *:not(:last-child) {
      border-bottom: calc(var(--unit) / 8) solid var(--neutral200);
    }
  }

  .header-languages {
    width: auto;
    height: 100%;
    box-shadow: inherit;
    margin-left: auto;
    .dropdown-button {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      gap: var(--unit);
      @extend %link-font_small;
      color: var(--neutral700);
      .material-icons {
        font-size: calc(2 * var(--unit));
      }
    }
    .dropdown-menu {
      width: max-content;
      left: auto;
      right: 0;
      top: auto;
      bottom: calc(-6 * var(--unit));
      transform: translateY(100%);
    }
  }

  .header-search {
    @extend %button-icon_interactive;
    margin-left: auto;
    color: var(--neutral700);
    &:hover {
      border: calc(var(--unit) / 8) solid var(--secondary400);
      background-color: var(--neutral0);
    }
    &:active {
      background-color: var(--neutral200);
    }
  }
  .header-contact {
    color: var(--neutral700);
  }
  .header-languages + .header-search {
    margin-left: calc(3 * var(--unit));
  }

  .header-top,
  .header-bottom {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .header-top {
    padding-top: calc(2 * var(--unit));
    margin-bottom: calc(2 * var(--unit));
    gap: calc(3 * var(--unit));
    .header-contact {
      margin-left: auto;
    }

    .header-languages {
      margin-left: 0;
      .dropdown-menu {
        top: auto;
        bottom: calc(-14 * var(--unit));
        transform: translateY(100%);
      }
    }
  }

  .header-contact-dropdown {
    .dropdown-button {
      @extend %button-icon_interactive;
      &:hover {
        border: calc(var(--unit) / 8) solid var(--secondary400);
        background-color: var(--neutral0);
      }
      &:active {
        background-color: var(--neutral200);
      }
    }
  }

  .header-items-dropdown {
    > .dropdown-button {
      @extend %button-icon_interactive;
      &:hover {
        border: calc(var(--unit) / 8) solid var(--secondary400);
        background-color: var(--neutral0);
      }
      &:active {
        background-color: var(--neutral200);
      }
    }
  }

  //MOBILE
  @media (max-width: $mobile) {
    .header,
    &.header {
      min-height: calc(8 * var(--unit));
      padding: var(--unit) calc(2 * var(--unit));
      position: relative;
    }
    .header-logo {
      height: calc(6 * var(--unit));
      width: auto;
    }
    .header-content {
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: calc(2 * var(--unit));
      .material-icons {
        color: var(--neutral800);
      }
    }
    .header-items-dropdown > .dropdown-menu,
    .header-contact-dropdown > .dropdown-menu {
      position: fixed;
      width: 100vw;
      top: calc(8 * var(--unit) + 1px);
      right: 0;
      left: auto;
      box-shadow: none;
      border-radius: 0;
      max-height: calc(100vh - 8 * var(--unit));
    }
    .header-items {
      padding: calc(2 * var(--unit));
      border-bottom: calc(var(--unit) / 8) solid var(--neutral200);
      .tree-container {
        & + .tree-container {
          margin-top: calc(3 * var(--unit));
        }
        .tree-element {
          padding: 0;
          font-size: calc(2.5 * var(--unit));
          .tree-element-icon-right {
            font-size: calc(3 * var(--unit));
          }

          &:hover {
            & > li {
              color: var(--secondary600);
            }
            background-color: transparent;
            border: 0;
            border-left: 0;
          }

          &:active {
            border: 0;
            border-left: 0;
            & > li {
              color: var(--secondary700);
            }
          }

          &:focus {
            background-color: inherit;
            border: 0;
            border-left: 0;
          }

          &:focus-visible {
            outline: none;
          }

          &:disabled {
            background-color: transparent;
            border: 0;
            border-left: 0;
            & > li {
              color: var(--neutral500);
            }
          }
        }
        .tree-element-item {
          color: var(--neutral700);
        }
        .tree-element + .tree-element-item {
          margin-top: calc(2 * var(--unit));
        }

        .tree-element-item + .tree-element-item {
          margin-top: var(--unit);
        }
      }
    }

    .header-contact-dropdown {
      .dropdown-button .material-icons {
        font-family: 'Material Icons Outlined';
      }
      .dropdown-menu {
        width: 100vw;
        padding: calc(2 * var(--unit));
        border-bottom: calc(var(--unit) / 8) solid var(--neutral200);
      }
    }

    .header-languages {
      padding: calc(2 * var(--unit));
      background: var(--neutral100);
      border-bottom: calc(var(--unit) / 8) solid var(--neutral200);
      width: 100%;
      .dropdown-button {
        color: var(--neutral800);
      }
      .dropdown-menu {
        position: fixed;
        width: 100vw;
        transform: translateY(calc(-1 * var(--unit)));
        box-shadow: none;
        border-radius: 0;
        max-height: fit-content;
        border-bottom: calc(var(--unit) / 8) solid var(--neutral200);
      }
    }

    //BACKGROUNDS MOBILE
    .bg04,
    &.bg04 {
      .header-content > .material-icons {
        color: var(--neutral0);
      }
      .header-items-dropdown .dropdown-menu,
      .header-contact-dropdown .dropdown-menu {
        background: var(--neutral800);
      }
      .header-items {
        .tree-container {
          .tree-element > li {
            color: var(--neutral0);
          }
          .tree-element-item {
            color: var(--neutral500);
          }
        }
      }
      .header-languages {
        background: var(--neutral800);
        color: var(--neutral0);
        .dropdown-menu {
          .dropdown-item {
            color: var(--neutral500);
          }
        }
      }
    }
  }

  //BACKGROUNDS
  .bg04,
  &.bg04 {
    .header,
    &.header {
      border-bottom: calc(var(--unit) / 8) solid var(--primary400);
      .tab-list {
        .tab-list-item {
          color: var(--neutral0);
        }
      }
    }
    .header-languages {
      .dropdown-button {
        color: var(--neutral0);
      }
      .dropdown-menu {
        color: var(--neutral800);
      }
    }

    .header-search {
      color: var(--neutral0);
      &:hover {
        border: calc(var(--unit) / 8) solid var(--secondary400);
        background-color: inherit;
      }
    }
    .header-contact {
      color: var(--neutral0);
    }
  }
}
