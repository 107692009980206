.tag-ds {
  .video-preview-container,
  &.video-preview-container {
    color: var(--neutral0);
    display: inline-flex;
    position: relative;
    .video-preview-image img {
      aspect-ratio: 16 / 9;

      &::before {
        content: '';
        background: linear-gradient(0deg, rgba(28, 48, 75, 0.24), rgba(28, 48, 75, 0.24));
        position: absolute;
        border-radius: var(--unit);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .video-preview-image {
      width: 100%;
      height: 100%;
    }
    .video-preview-image img {
      border-radius: var(--unit);
      height: 100%;
      width: 100%;
    }
    .video-preview-header,
    .video-preview-footer {
      padding: calc(2 * var(--unit));
      position: absolute;
    }
    .video-preview-footer {
      width: 100%;
      display: inline-flex;
      align-items: flex-end;
      height: 100%;
      &:not(.video-preview-footer-small) .video-preview-timer:first-child {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .video-preview-footer-large {
      flex-direction: column;
      justify-content: flex-end;
      .video-preview-title {
        flex: 0;
        align-self: start;
        position: relative;
        font-size: calc(3 * var(--unit));
        font-weight: 400;
      }
      .video-preview-title:first-child {
        width: 100%;
      }
      .video-preview-subtitle {
        align-self: start;
        position: absolute;
      }
      .video-preview-subtitle:nth-last-child(2) {
        width: 100%;
        position: relative;
      }
      .video-preview-timer {
        font-size: calc(2.5 * var(--unit));
        font-weight: 400;
      }
      .video-preview-timer:last-child {
        width: 100%;
        position: relative;
      }
    }
    .video-preview-footer-small {
      .video-preview-timer {
        flex: auto;
        justify-content: start;
      }
      .video-preview-play-button-container {
        display: flex;
        align-items: center;
      }
      & > .video-preview-play-button-container {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .video-preview-tag {
      @extend %tag-font;
      border-radius: calc(2 * var(--unit));
      background-color: var(--secondary100);
      color: var(--neutral800);
      width: fit-content;
      padding: calc(var(--unit) / 2) calc(2 * var(--unit));
    }
    .video-preview-title {
      @include font(medium);
      font-weight: 500;
      flex: 1;
    }
    .video-preview-subtitle {
      @extend %small-title;
      font-weight: 400;
    }
    .video-preview-small-play-title {
      @include font(small);
      font-weight: 500;
      text-transform: uppercase;
      padding-right: var(--unit);
    }
    .video-preview-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: calc(10 * var(--unit));
      transform: translate(-50%, -50%);
      opacity: 56%;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .video-preview-medium-play-button {
      font-size: calc(6 * var(--unit));
    }
    .video-preview-small-play-button {
      opacity: 56%;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .video-preview-timer {
      @include font(medium);
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
