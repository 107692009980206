.tag-ds {
  .hero_content,
  .hero_search,
  &.hero_content,
  &.hero_search,
  .hero_content_accent,
  .hero_search_accent,
  &.hero-content_accent,
  &.hero_search_accent {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .hero_interior,
  &.hero_interior,
  .hero_interior_accent,
  &.hero_interior_accent,
  .hero_interior_light,
  &.hero_interior_light {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    .hero_interior-back-btn {
      display: flex;
      align-items: center;
      background: none;
      border: 0;
      text-transform: uppercase;
    }
    .hero_interior-left {
      display: block;
      padding: calc(5 * var(--unit)) calc(6 * var(--unit)) calc(10.5 * var(--unit)) calc(10 * var(--unit));
      margin-right: 50%;
      background: var(--neutral100);
      .hero-body {
        color: var(--neutral700);
      }
    }
    .hero-body + .hero-footer {
      margin-top: calc(3 * var(--unit));
    }
    .hero-background {
      width: 50%;
      right: 0;
      left: inherit;
    }
  }

  .hero_interior_accent,
  &.hero_interior_accent {
    .hero_interior-left {
      background: var(--accentColor);
      .hero-body {
        color: var(--neutral800);
      }
      .avatar-subtitle {
        color: var(--neutral800);
      }
    }
  }

  .hero_interior_light,
  &.hero_interior_light {
    .hero_interior-left {
      background: var(--neutral0);
    }
  }

  .hero-header {
    flex: 0 1 auto;
    margin-bottom: calc(2 * var(--unit));
  }

  .hero-body {
    flex: 0 1 auto;
    margin-bottom: auto;
  }

  .hero-footer {
    flex: 0 1 auto;
    margin-top: auto;

    .hero-footer-items {
      display: flex;
      gap: calc(3 * var(--unit));
    }
  }

  .hero-header,
  .hero-body,
  .hero-footer {
    z-index: 1;
  }

  .hero-background {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .hero-background {
    background: var(--neutral100);
  }

  .hero_content_accent .hero-background,
  &.hero_content_accent .hero-background,
  .hero_search_accent .hero-background,
  &.hero_search_accent .hero-background {
    background: var(--accentColor);
  }

  img.hero-background ~ * {
    color: var(--neutral0);
  }

  .hero_content,
  &.hero_content,
  .hero_content_accent,
  &.hero_content_accent,
  .hero_search,
  &.hero_search,
  .hero_search_accent,
  &.hero_search_accent {
    display: flex;
    flex-direction: column;
    padding: calc(15 * var(--unit)) calc(18 * var(--unit)) calc(7 * var(--unit));
    width: 100vw;
    height: 100vh;
  }

  .hero_search,
  &.hero_search,
  .hero_search_accent,
  &.hero_search_accent {
    .hero-header,
    .hero-footer {
      text-align: center;
    }
    .hero-header {
      margin-bottom: calc(5 * var(--unit));
    }
    .hero-footer-items {
      justify-content: center;
    }
    .hero-body {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: calc(2 * var(--unit));
    }
  }

  @media (max-width: $mobile) {
    .hero_content,
    &.hero_content,
    .hero_content_accent,
    &.hero_content_accent,
    .hero_search,
    &.hero_search,
    .hero_search_accent,
    &.hero_search_accent {
      padding: calc(6 * var(--unit)) calc(2.5 * var(--unit)) calc(4 * var(--unit));
    }
    .hero_search,
    &.hero_search,
    .hero_search_accent,
    &.hero_search_accent {
      .hero-body {
        display: block;
        > * + * {
          margin-top: calc(2 * var(--unit));
        }
      }
    }

    .hero_interior,
    &.hero_interior,
    .hero_interior_accent,
    &.hero_interior_accent,
    .hero_interior_light,
    &.hero_interior_light {
      display: block;

      .hero_interior-left {
        display: block;
        padding: calc(25 * var(--unit)) calc(4 * var(--unit)) calc(7 * var(--unit));
        margin: 0;
      }

      .hero-background {
        width: 100%;
        max-height: calc(20 * var(--unit));
        right: 0;
        left: inherit;
      }
    }
  }
}
