@mixin carousel-button($position) {
  @extend %button-icon_interactive;
  background: var(--neutral100);
  user-select: none;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  &:has(.material-icons:not(.left, .right)) {
    height: calc(5 * var(--unit));
    width: calc(5 * var(--unit));
  }

  @if $position == 'left' {
    margin-left: calc(3 * var(--unit));
  } @else if $position == 'right' {
    margin-right: calc(3 * var(--unit));
    margin-left: auto;
    right: 0;
  }
}

.tag-ds {
  .carousel,
  &.carousel {
    position: relative;
  }

  .carousel-container {
    position: relative;
    display: inline-block;
  }

  button.carousel-left {
    @include carousel-button(left);
  }

  button.carousel-right {
    @include carousel-button(right);
  }

  .carousel-dots-container {
    position: relative;
    bottom: calc(4 * var(--unit));
    display: flex;
    justify-content: center;
    gap: var(--unit);
  }

  button.carousel-dot {
    border: 0;
    padding: 0;
    min-width: auto;
    height: var(--unit);
    width: var(--unit);
    background-color: var(--neutral200);
    border-radius: 50%;

    &:hover {
      background-color: var(--secondary600);
    }

    &:active {
      background-color: var(--neutral200);
    }

    &:focus-visible {
      outline: calc(var(--unit) / 2) solid var(--secondary300);
    }

    &:disabled {
      border: calc(var(--unit) / 8) solid var(--neutral200);
      background-color: var(--neutral0);
    }
  }

  button.carousel-dot_active {
    @extend .carousel-dot;
    background-color: var(--accent500);
  }

  .carousel-container_outlined {
    @extend .carousel-container;

    & > .carousel-dots-container {
      bottom: calc(-3 * var(--unit));
    }

    & > .carousel-left {
      margin-left: calc(-3 * var(--unit) - 5 * var(--unit));
      background-color: var(--neutral100);

      &_disabled {
        margin-left: calc(-3 * var(--unit) - 5 * var(--unit));
        color: var(--neutral500);
      }
    }

    & > .carousel-right {
      margin-right: calc(-3 * var(--unit) - 5 * var(--unit));
      background-color: var(--neutral100);

      &_disabled {
        margin-right: calc(-3 * var(--unit) - 5 * var(--unit));
        color: var(--neutral500);
      }
    }
  }
}
