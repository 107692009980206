@mixin fileUploader($type: 'normal', $size: 'large', $full: false) {
  @if $type == 'drag-drop' {
    @if $full == 'true' {
      width: 100%;

      .file-uploader-files-container {
        display: flex;
        flex-wrap: wrap;
        gap: calc(2 * var(--unit));

        .file-uploader-file-container {
          flex: 1;
          max-width: calc(100% / 3 - var(--unit));
        }
      }
    } @else {
      width: calc(52.5 * var(--unit));
    }
  } @else {
    @if $full == true {
      width: 100%;
    } @else {
      width: calc(30 * var(--unit));
    }

    @if $size == 'small' {
      .button-primary_small,
      .button-secondary_small {
        width: 100%;
        .material-icons {
          font-family: 'Material Icons Outlined';
        }
      }
      .file-uploader-file,
      .file-uploader-file_error {
        padding: var(--unit) var(--unit) calc(2 * var(--unit));
      }
    } @else {
      .button-primary_large,
      .button-secondary_large {
        width: 100%;
      }
    }
  }
  input {
    display: none;
  }
}
.tag-ds {
  .file-uploader-wrapper,
  &.file-uploader-wrapper {
    @include fileUploader(normal, large, false);
    &_disabled {
      @include fileUploader(normal, large, false);
    }
    &_full {
      @include fileUploader(normal, large, true);
      &_disabled {
        @include fileUploader(normal, large, true);
      }
    }
    &_small {
      @include fileUploader(normal, small, false);
      &_disabled {
        @include fileUploader(normal, small, false);
      }
      &_full {
        @include fileUploader(normal, small, true);
        &_disabled {
          @include fileUploader(normal, small, true);
        }
      }
    }

    &_drag-drop {
      @include fileUploader(drag-drop, large, false);
      &_disabled {
        @include fileUploader(drag-drop, large, false);
        .file-uploader-drag-and-drop {
          pointer-events: none;
          border: calc(var(--unit) / 8) dashed var(--neutral500);
          span {
            @extend .disabled;
          }
        }
      }
      &_full {
        @include fileUploader(drag-drop, large, true);
        &_disabled {
          @include fileUploader(drag-drop, large, true);
          .file-uploader-drag-and-drop {
            pointer-events: none;
            border: calc(var(--unit) / 8) dashed var(--neutral500);
            span {
              @extend .disabled;
            }
          }
        }
      }
    }
  }

  .file-uploader-helper-text {
    width: 100%;
    display: block;
    word-break: normal;
    margin-top: var(--unit);
  }

  .file-uploader-drag-and-drop {
    display: block !important;
    text-align: left;
    width: 100%;
    height: calc(14 * var(--unit));
    border-radius: var(--unit);
    border: calc(var(--unit) / 8) dashed var(--primaryColor);
    padding: var(--unit);
    cursor: pointer;
    span {
      @include font(base);
      text-align: center;
      color: var(--secondaryColor);
    }
    &:active {
      border: calc(var(--unit) / 4) solid var(--secondary400);
    }
  }

  .file-uploader-files-container .file-uploader-file-container:not(:last-child) {
    margin-bottom: var(--unit);
  }

  .file-uploader-file {
    display: flex;
    align-items: center;
    width: 100%;
    padding: calc(2 * var(--unit));
    border-radius: var(--unit);
    background-color: var(--neutral200);
    button {
      @extend %button-icon_interactive;
      margin-left: auto;
      &:has(.material-icons):has(:not(.left, .right)) {
        width: calc(2 * var(--unit));
        height: calc(2 * var(--unit));
        padding: 0;
      }

      &:focus-visible {
        border-color: var(--neutral500);
      }

      &:disabled {
        display: none;
      }
      .cancel {
        color: var(--neutral500);
        font-size: calc(2 * var(--unit));
      }
    }
    .material-icons,
    .spinner {
      margin-left: auto;
      font-size: calc(2 * var(--unit));
    }
    .check {
      color: var(--successColor);
    }
    .error {
      color: var(--errorColor);
    }
    .error ~ button {
      margin-left: var(--unit);
    }
  }

  .file-uploader-file_error {
    @extend .file-uploader-file;
    border: calc(var(--unit) / 8) solid var(--errorColor);
  }

  .file-uploader-file-name {
    @include font(base);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: var(--unit);
  }
}
