.header {
  &.isFixed {
    position: fixed;
    width: 100%;
  }

  .banner {
    display: flex;
    align-items: center;
    background: var(--banner-background);
    border-radius: 0;
    border-bottom: 1px solid var(--neutral300);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    @media screen and (max-width: 768px) {
      border-radius: 0;
    }

    .logo {
      background: var(--logo);
      background-repeat: no-repeat;
      background-size: cover;
      width: var(--logo-width);
      height: var(--logo-height);
      margin: var(--logo-margin);
    }

    .title {
      font-size: 24px;
      font-weight: 300;
      margin-left: 4px;
      padding-left: 12px;
      margin-top: 8px;
      border-left: 2px solid var(--neutral700);
      color: var(--title);

      @media screen and (max-width: 320px) {
        font-size: 18px;
      }
    }
  }
}
